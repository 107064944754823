import { createContext, useEffect, useState } from 'react'
import { getSystemLanguage, fetchLanguages } from '../includes/dbLanguagesFunc'
import { getAllKeyTranslations } from '../includes/dbSourcesFunc'

export const LanguageContext = createContext(null)

const LanguageProvider = ( { children }) => {
    const [language, setLanguage] = useState(null)    //Die ausgewählte Sprache
    const [languages, setLanguages] = useState([])    //Alle verfügbaren Sprachen
    const [labels, setLabels] = useState({})          //Übersetzungen der Labels (also Sources die in der Ui verwendet werden und über eine Key-Wert abgerufen werden.)

    useEffect(() =>{

       
        (async () => {

            //Die im Browser eingestellte Sprache. Kann beisp. de oder de-CH sein.
            const prefLanguages = navigator.language.toLowerCase().split('-')  //Die Liste enthällt dann Bsp. [de, ch]
        
            //Alle zur Verfügung stehenden Sprachen laden.
            let allLanguages = null

            if(languages.keys.length === 0){
                const answer = await fetchLanguages()
                if(answer.status === 1){
                    allLanguages = answer.results
                    setLanguages(answer.results)
                }
            }

            //Wenn die Seite neu geladen wird, werden alle useState-Variablen zurückgesetzt. Wir können aber schauen, ob diese in der Session gespeichert sind.
            if (sessionStorage.getItem("language") !== null){
                //Die Sprache ist in der Session schon gesetzt. Das Session-Item ist aber JSON, also in ein Object umwandeln und als language festlegen.
                const lang = JSON.parse(sessionStorage.getItem("language"));
                changeLanguage(lang);
            }else{
                //Ev. existiert die im Browser eingestellte Sprache, dann diese verwenden!

                const found = allLanguages.filter(v => prefLanguages.includes(v.symbol.toLowerCase()))

                if(found && found.length !== 0){
                    changeLanguage(found[0])
                }else{
                    //Systemsprache holen.
                    const system = allLanguages.filter(v => v.system === '1')
                    if(system && system.length !== 0){
                        changeLanguage(system[0])
                    }
                }
            }

        })();
    },[])


    function changeLanguage(language){
        if(!language){return false}

        //Die Sprache wurde geändert. Als Object in language setzten, und als JSON in der Session.
        sessionStorage.setItem('language', JSON.stringify(language));
        setLanguage(language);

        (async () => {
            if(language){
                const labels = await getAllKeyTranslations(language.id)
                setLabels(labels)
            }
        })();
    }

    //Besser die Language so abzufragen, weil mit setLanguage immer eine gewisse Zeit verstreicht.
    const getLanguage = () => {
        if (language){
            return language
        }else if(sessionStorage.getItem("language") !== null){
            const lang = JSON.parse(sessionStorage.getItem("language"));
            return lang
        }
    }

    //gibt das Label in der aktuell gewählten Sprache zurück. Existiert keine Source mit dem angegebenen Key, wird der key selber zurückgegeben.
    const getLabel = (key) => {
        if(labels[key]){
            return labels[key]
        }else{
            return key
        }
    }

    
    const value = {
        language,
        languages,
        labels,
        getLabel,
        getLanguage,
        changeLanguage
    }

    return ( 
        <LanguageContext.Provider value={value}>
            { children }
        </LanguageContext.Provider>
     );
}
 
export default LanguageProvider;