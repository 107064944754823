import { Button, Container, Skeleton, Stack, Typography, IconButton, Alert, Tooltip, TextField, Box } from "@mui/material"
import { useEffect, useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSnackbar } from 'notistack'
import { fetchSourceBySource, createSource, fetchSource } from "../includes/dbSourcesFunc"
import { fetchTemplate, createTemplate, editTemplate, deleteTemplate } from "../includes/dbTemplatesFunc"
import { fetchTemplateentriesFromTemplate, editTemplateentry, createTemplateentry, deleteTemplateentry } from "../includes/dbTemplateentriesFunc"
import { fetchFile } from "../includes/dbFilesFunc"
import { AuthContext } from '../components/AuthProvider'
import EntryForm from "../components/EntryForm"
import ImageDialog from "../components/ImageDialog"
import SourceTextfield from "../components/SourceTextfield"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add';


const delay = t => new Promise(resolve => setTimeout(resolve, t));


const Templatepage = () => {
    const { id } = useParams()   //ID aus der URL, wenn also das Template 4587 mit template/4587 aufgerufen werden soll.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey } = useContext(AuthContext)
    const [ imageDialogOpen, setImageDialogOpen ] = useState(false)
    const [ name, setName ] = useState('')
    const [ info, setInfo ] = useState('')
    const [ image, setImage ] = useState(null)
    const [ title, setTitle ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ templateId, setTemplateId ] = useState()
    const [ templateentries, setTemplateentries ] = useState([]);
    const [ errors, setErrors ] = useState([])
    const [ idExists, setIdExists ] = useState('false')
    const navigate = useNavigate()
    

    useEffect(()=>{
        //Falls eine ID angegeben wurde, wird diese geladen
        setTemplateId(id)
        if(id){
            //Bestehendes Template laden
            (async () => {
                let answer = await fetchTemplate( id )
                if(answer.status === 1 && answer.count === 0){
                    setIdExists(false)
                    
                }else if(answer.status === 1){
                    setIdExists(true)
                    const template = answer.results

                    setName(template.name)
                    setInfo(template.info)
                    
                    if(template.title_id){
                        answer = await fetchSource( template.title_id )
                        setTitle( answer.results.source )
                    }
                    if(template.description_id){
                        answer = await fetchSource( template.description_id )
                        setDescription( answer.results.source )
                    }
                    if(template.image_id){
                        const loadImage = await fetchFile( template.image_id )
                        setImage(loadImage.results)
                    }

                    answer = await fetchTemplateentriesFromTemplate( id )
                    setTemplateentries( answer.results )
                }
            })();

        }

    },[ id ])


    //Ein ImageDialog zur Auswahl eines Bildes wurde geschlossen. Als Result wird entweder eine Object eines Bildes (file) oder null zurückgegeben.
    const handleImageDialogClose = ( result ) => {
        if(result){
            setImage(result)
        }
        setImageDialogOpen(false)
    }


    //Ein Templateentry aus der Auflistung löschen
    const handleRemove = async (index) => {
        const newEntries = [...templateentries]
        newEntries.splice(index,1)
        setTemplateentries([])   //Wieso auch immer, ohne dies werden die neuen Entries nicht korrekt dargestellt im Browser!
        await delay(1)
        setTemplateentries(newEntries)
    }

    //Dieses Template speichern.
    const handleSave = async () => {
        let aktTemplateId = templateId  //müssen wir machen, da wenn ein neues Template erstellt wird, müssen wir die Id weiter verwenden. Und mit UseState wird templateId async zugewiesen.

        //Als erstes kontrollieren, ob die Daten korrekt sind.
        for (let index = 0; index < templateentries.length; index++) {
            const templateentry = templateentries[index];
            if(!templateentry || !templateentry.group_source || !templateentry.caption_source){
                enqueueSnackbar(`Das Templateentry mit dem Index ${index} ist nicht vollständig definiert.`, {variant: 'error'})
                return false
            }            
        }
        //Nun die Daten speichern, ev. template, sources und templateentries neu anlegen.
        const template = { name: name, info: info }

        if(image){
            template.image_id = image.id
        }else{
            template.image_id = null
        }

        let answer = null

        //Kontrollieren, ob Source für Title schon existiert, ansonsten anlegen
        if(title !== ''){
            answer = await checkSource(title)
            if(answer){ template.title_id = answer }else{ return false}
        }else{
            template.title_id = null
        }

        //Kontrollieren, ob Source für Description schon existiert
        if(description !== ''){
            answer = await checkSource(description)
            if(answer){ template.description_id = answer }else{ return false}
        }else{
            template.description_id = null
        }
        
        if(!aktTemplateId){
            //Template existiert noch nicht, und muss neu angelegt werden.
            answer = await createTemplate( template.name, template.info, template.image_id, template.title_id, template.description_id, apikey)
            if(answer.status === 1){
                aktTemplateId = answer.id
                setTemplateId(aktTemplateId)
                enqueueSnackbar('Template neu angelegt!' , {variant: 'success'})
            }else{
                enqueueSnackbar('Template konnte nicht neu erstellt werden! ' + answer.message , {variant: 'error'})
                return false
            }
        }else{
            //Template ändern.
            answer = await editTemplate( aktTemplateId, template.name, template.info, template.image_id, template.title_id, template.description_id, apikey)
            if(answer.status === 1){
                enqueueSnackbar('Template erfolgreich geändert!' , {variant: 'success'})
            }else{
                enqueueSnackbar('Template konnte nicht geändert werden! ' + answer.message , {variant: 'error'})
                return false
            }
        }

        //Entries
        for (let index = 0; index < templateentries.length; index++) {
            const templateentry = templateentries[index];
            //Sources des Tempateentries neu erstellen wenn noch nicht vorhanden.
            const groupId = await checkSource(templateentry.group_source)
            const captionId = await checkSource(templateentry.caption_source)
            const contentId = templateentry.content_source ? await checkSource(templateentry.content_source) : null
            const linkId = templateentry.link_source ? await checkSource(templateentry.link_source) : null

            if( !groupId || !captionId || ( templateentry.content_source && !contentId) || ( templateentry.link_source && !linkId )){
                enqueueSnackbar(`Probleme beim Erstellen einer Source des Templateentries mit dem Index ${index}.`, {variant: 'error'})
                return false
            }

            //Templateentry anlagen oder ändern
            if(templateentry.id){
                //Templateentry ändern
                answer = await editTemplateentry(templateentry.id, aktTemplateId, groupId, captionId, contentId, linkId, apikey)
                if(!answer.status === 1){
                    enqueueSnackbar(`Probleme beim Aendern des Templateentries mit dem Index ${index}.`, {variant: 'error'})
                    return false
                }
            }else{
                //Templateentry neu anlegen
                answer = await createTemplateentry(aktTemplateId, groupId, captionId, contentId, linkId, apikey)
                if(answer.status === 1){
                    templateentry.id = answer.id
                }else{
                    enqueueSnackbar(`Probleme beim Erstellen des Templateentries mit dem Index ${index}.`, {variant: 'error'})
                    return false
                }
            }
        }
        enqueueSnackbar('Templateentries erfolgreich gespeichert.' , {variant: 'success'})

        //Templateentries löschen. Alle Templateentries die in der DB vorhanden sind, aber nicht im Array müssen gelöscht werden:
        answer = await fetchTemplateentriesFromTemplate( aktTemplateId )
        if(answer.status === 1){
            const dbEntries = answer.results

            for (let index = 0; index < dbEntries.length; index++) {
                const dbEntry = dbEntries[index];
                if(!templateentries.find(entry => entry.id === dbEntry.id)){
                    answer = await deleteTemplateentry( dbEntry.id, apikey )
                    if(answer.status === 1){
                        enqueueSnackbar(`Templateentry mit der Id ${dbEntry.id} gelöscht!` , {variant: 'success'})
                    }else{
                        enqueueSnackbar(`Probleme beim Löschen des Templateentries mit der Id ${dbEntry.id}. ${answer.message}`, {variant: 'error'})
                        return false
                    }
                }
            }
        }else{
            enqueueSnackbar('Probleme beim Datenbankzugriff. Abfrage aller Templateentries nicht möglich.', {variant: 'error'})
            return false            
        }

        navigate('/templates/')
        enqueueSnackbar('Erfolgreich gespeichert!' , {variant: 'success'})
    }


    //Das aktuelle Template löschen.
    const handleDelete = async () => {
        if(templateId){
            //Dieses Template wurde in der DB gespeichert und muss nun gelöscht werden:
            const answer = await deleteTemplate(templateId, apikey)
            if(answer.status === 1){
                enqueueSnackbar('Template erfolgreich gelöscht!' , {variant: 'success'})
            }else{
                enqueueSnackbar('Probleme beim Datenbankzugriff. Template konnte nicht gelöscht werden.', {variant: 'error'})
                return false
            }
        }
        
        navigate('/templates/')
    }


    //Falls die Source nicht existiert, diese neu anlegen.
    //Return: Id der schon vorhandenen oder neu angelegten Source oder False bei einem Fehler.
    const checkSource = async ( source ) => {
        let answer = await fetchSourceBySource(source)
        if(answer.status === 1 && answer.count === 0){
            //Source muss neu angelegt werden.
            answer = await createSource(source, null, 0, apikey)
            if(answer.status === 1){ return answer.id }else{
                enqueueSnackbar('Fehler beim Erstellen einer Source! ' + answer.message , {variant: 'error'})
                return false
            }
        }else if(answer.status === 0){
            enqueueSnackbar('Fehler beim Erstellen einer Source! ' + answer.message , {variant: 'error'})
            return false
        }else{
            return answer.id
        }
    }


    return ( 
        <Container>
            <Typography variant='h1'>
                Template
            </Typography>

            { ( templateId && !idExists ) ? (
                <Typography>Das Template mit der Id {templateId} existiert nicht.</Typography>
            ) : (
                <Box>
                    <Stack direction='column' spacing={2} sx={{ mb: 2, ml: 1}}>
                        { templateId &&
                            <Typography variant="body1">Template-Id: { templateId }</Typography>
                        }
                        <TextField
                            variant='standard'
                            label='Name'
                            id='name'
                            helperText={ errors.name }
                            error= { errors.name ? true : false  }
                            fullWidth
                            value={ name ? name : '' }
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            variant='standard'
                            label='Info'
                            id='info'
                            multiline
                            helperText={ errors.info }
                            error= { errors.info ? true : false  }
                            fullWidth
                            value={ info ? info : '' }
                            onChange={(e) => setInfo(e.target.value)}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                        <Button
                            onClick={() => setImageDialogOpen(true)}
                        >
                            { image ?
                                <Tooltip
                                    title={
                                        <>
                                        <p>{image.name}</p>
                                        <p>{image.description}</p>
                                        <Button variant='contained' href={'/files/' + image.id}>go to</Button>
                                        </>
                                    }
                                >
                                    <img
                                        onLoad={(e) => {
                                            if(e.target.naturalWidth > 100 || e.target.naturalHeight > 100){
                                                setErrors(prev => ({
                                                    ...prev,
                                                    image: `Bild sollte nicht grösser sein als 100x100px. Aktuell sind es ${e.target.naturalWidth}x${e.target.naturalHeight}`
                                                }))
                                            }else{
                                                setErrors(prev => ({
                                                    ...prev,
                                                    image: null
                                                }))
                                            }
                                        }}
                                        src={process.env.REACT_APP_IMAGEPFAD + image.path}
                                        alt={image.alt}
                                        style={{height: 100, maxWidth: 400}}
                                    />
                                </Tooltip>
                            : 
                                <Skeleton variant="rounded" width={100} height={100} />
                            }
                        </Button>
                        { image && 
                            <IconButton
                                onClick={() => {
                                    setImage(null)
                                    setErrors(prev => ({
                                        ...prev,
                                        image: null
                                    }))                            
                                }}
                                sx={{marginRight: '20px'}}
                            >
                                <DeleteOutlineIcon/>
                            </IconButton>
                        }
                        <Stack  direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                            <SourceTextfield label="Titel" required={false} sx={{width: 300}} value={ title } onChange={(n) => setTitle(n)}/>
                            <SourceTextfield label="Beschreibung" required={false} sx={{width: 300}} value={ description } onChange={(n) => setDescription(n)}/>
                        </Stack>
                        { errors.image && <Alert severity="warning">{errors.image}</Alert>}

                    </Stack>

                    <Typography variant='h4' sx={{mt: 5}}>Templateentries</Typography>
                    
                    <Stack direction='column' spacing={2} alignItems="flex-start">
                        {templateentries &&
                            templateentries.map((templateentry, index) => (
                                <Stack key={ index } direction='row' spacing={1} alignItems="flex-end" >
                                    <IconButton onClick={() => handleRemove( index )}>
                                        { templateentry && templateentry.id ? <DeleteOutlineIcon color="primary" /> : <ClearIcon color="primary"/> } 
                                    </IconButton>
                                    <Typography variant='body1' color='primary' style={{marginBottom: 4, marginRight: 15}}>{index}</Typography>
                                    <EntryForm entry={ templateentry } contentRequired={false}/>
                                </Stack>
                            ))
                        }
                        <IconButton
                            onClick={() => {
                                setTemplateentries([...templateentries, {}])
                            }}
                        >
                            <AddIcon color="primary"/> 
                        </IconButton>
                    </Stack>

                    <Stack direction='row' spacing={1} sx={{mt: 5}}>
                        <Button
                            variant="contained"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                        { templateId && 
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={handleDelete}
                            >
                                Löschen
                            </Button>
                        }
                        <Button
                            variant="contained"
                            onClick={() => navigate('/templates')}
                        >Zur Uebersicht</Button>
                    </Stack>
                </Box>
            )}
            <ImageDialog
                keepMounted
                open={ imageDialogOpen }
                onClose={(e) => handleImageDialogClose(e)}
            />
        </Container>
     );
}



export default Templatepage;