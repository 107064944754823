import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { FormGroup, FormControlLabel, Checkbox, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchUsers, fetchUser, createUser, editUser, deleteUser, exitsUser, changePassword} from '../includes/dbUsersFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'username', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'E-Mail', width: 300 },
    { field: 'apikey', headerName: 'API-Key', width: 200 },
    { field: 'administrator', headerName: 'Admin', width: 80 },
    { field: 'disabled', headerName: 'Deaktiviert', width: 80 }
];

const Userspage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isAdmin, user: operator } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['users', apikey], () => fetchUsers(0, null, apikey))
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})


    useEffect(() => {
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Eine Zeile in der Tabelle ausgewählt. Diesen Datensatz bearbeiten.
    const handleEdit = async ( id ) => {
        const row = await fetchUser(id, apikey)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein User mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({username: '', password: '', apikey: '', email: '', disabled: '0', administrator: '0'})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setErrors({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }

    
    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async () => {
        let collect = {}
        let emailcheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!values.username){
            collect.username = 'Name eingeben.'
        }else if( mode === 'create' && await exitsUser( values.username, null, apikey )){
            collect.username = 'Name wird schon verwendet.'
        }else if( mode === 'edit' && await exitsUser( values.username, values.id, apikey )){
            collect.username = 'Name wird schon verwendet.'
        }

        if(!values.email){
            collect.email = 'E-Mail eingeben.'
        }else if( !emailcheck.test(values.email)){
            collect.email = 'Dies ist keine E-Mail!'
        }
        
        if(mode === 'create' && !values.password){
            collect.password = 'Passwort eingeben.'
        }

        if(mode === 'edit' && !values.apikey){
            collect.apikey = 'Key eingeben.'
        }
        
        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        if(mode === 'create'){
            //Neuen User erstellen.
            const res = await createUser(values.username, values.email, values.password, values.administrator, values.disabled, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("User erfolgreich angelegt.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //User ändern.
            const res = await editUser(values.id, values.username, values.email, values.apikey, values.administrator, values.disabled, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("User erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Ein User löschen
    const handleDelete = async (id) => {
        if(operator.id === id){
            enqueueSnackbar("Der eingeloggte User kann nicht gelöscht werden.", {variant: "error"})
            return
        }        
        const result = await deleteUser(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("User erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            if(operator.id === id){
                enqueueSnackbar("Der eingeloggte User kann nicht gelöscht werden.", {variant: "error"})
            }else{
                const result = await deleteUser(id, apikey)
                if(result.status === 0){
                    enqueueSnackbar(result.message, {variant: "error"})
                }
            }
        }
        refetch()
    }


    //Password ändern.
    const handleChangePassword = async () => {
        if(!values.password){
            setErrors(prev =>({
                ...prev,
                password: 'Passwort eingeben!'
            }))
        }else{
            const result = await changePassword(values.id, values.password, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }else{
                enqueueSnackbar("Password geändert.", {variant: "success"})
                handleCloseDrawer()
                refetch()
            }            
        }
    }


    
    return (
        <Container>
            <Typography variant="h1">
                Users
            </Typography>
            { isAdmin() &&
                <div className="data">
                    <div className="data-table-toolbar">
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                            <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                                <AddIcon />
                            </IconButton>
                            <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton color="primary" onClick={refetch}>
                                <RefreshIcon />
                            </IconButton>
                            { isLoading && <HourglassBottomIcon color='warning' />}
                        </Stack>
                        <div className="data-table" style={{ height: 650, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data.results : []}
                                columns={columns}
                                disableSelectionOnClick
                                checkboxSelection
                                pageSize={15}
                                rowsPerPageOptions={[15]}                    
                                disableColumnFilter
                                onRowClick={(e) => handleEdit(e.id)}
                                onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                selectionModel={selectionModel}
                            />                            
                        </div>
                    </div>
                </div>
            }
            { !isAdmin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Sprache ändern...'}
                        { mode === "create" && 'Neue Sprache anlegen...'}
                    </Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2}>
                                { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                <TextField
                                    variant='standard'
                                    label='Name'
                                    id='username'
                                    required
                                    fullWidth
                                    helperText={ errors.username }
                                    error= { errors.username ? true : false  }
                                    value={ values.username }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            username: e.target.value
                                        }))
                                    }}
                                />
                                <TextField
                                    variant='standard'
                                    label='E-Mail'
                                    id='email'
                                    fullWidth
                                    helperText={ errors.email }
                                    error= { errors.email ? true : false  }
                                    value={ values.email }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            email: e.target.value
                                        }))
                                    }}                                    
                                />
                                { mode === 'edit' && 
                                    <>
                                        <TextField
                                            variant='standard'
                                            label='API-Key'
                                            id='apikey'
                                            required
                                            fullWidth
                                            helperText={ errors.apikey }
                                            error= { errors.apikey ? true : false  }
                                            value={ values.apikey}
                                            inputProps={ {maxLength: 14}}
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    apikey: e.target.value
                                                }))
                                            }}
                                        />                                    
                                    </>                                
                                }
                                { mode === 'create' &&
                                    <>
                                        <TextField
                                            variant='standard'
                                            label='Passwort'
                                            id='password'
                                            type="password"                                        
                                            required
                                            fullWidth
                                            helperText={ errors.password }
                                            error= { errors.password ? true : false  }
                                            value={ values.password}
                                            inputProps={ {maxLength: 200}}
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    password: e.target.value
                                                }))
                                            }}
                                        />
                                    </>
                                }
                                <FormGroup>
                                    <FormControlLabel label="Administrator" control={
                                        <Checkbox
                                            id="administrator"
                                            checked={ values.administrator === '1' ? true : false }
                                            onChange={(e) => {
                                                const newValue = e.target.checked === true ? '1' : '0'
                                                setValues(prev =>({
                                                    ...prev,
                                                    administrator: newValue
                                                }))
                                            }}
                                        />
                                    } />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel label="Deaktiviert" control={
                                        <Checkbox
                                            id="disabled"
                                            checked={ values.disabled === '1' ? true : false }
                                            onChange={(e) => {
                                                const newValue = e.target.checked === true ? '1' : '0'
                                                setValues(prev =>({
                                                    ...prev,
                                                    disabled: newValue
                                                }))
                                            }}
                                        />
                                    } />
                                </FormGroup>
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='submit'
                                    onClick={async(e) => {
                                        e.preventDefault()
                                        const res = await handleCheck()
                                        res && handleSave()
                                    }}
                                >Save</Button>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                { mode==='edit' &&
                                    <Button variant='contained' color="warning"
                                        onClick={() => {
                                            handleDelete( values.id )
                                        }}
                                    >Delete</Button>
                                }
                            </Stack>
                            { mode === 'edit' && operator.id === values.id &&
                                <Alert severity="warning">
                                    Der angemeldete User kann nicht gelöscht werden!
                                </Alert>
                            }
                            { mode === 'edit' && 
                                <Stack spacing={2}>
                                    <TextField
                                        variant='standard'
                                        label='Passwort'
                                        id='password-change'
                                        type="password"                                    
                                        required
                                        fullWidth
                                        helperText={ errors.password }
                                        error= { errors.password ? true : false  }
                                        value={ values.password}
                                        inputProps={ {maxLength: 200}}
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                password: e.target.value
                                            }))
                                        }}
                                    />
                                    <Button variant="contained" onClick={handleChangePassword}>
                                        Passwort ändern
                                    </Button>
                                </Stack>
                            }
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten User wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                            Der angemeldete User kann nicht gelöscht werden!
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}
 
export default Userspage;