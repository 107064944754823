import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchLanguages, fetchLanguage, createLanguage, editLanguage, deleteLanguage, isSystem} from '../includes/dbLanguagesFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'leo', headerName: 'Leo', width: 200 },
    { field: 'symbol', headerName: 'Symbol', width: 100 },
    { field: 'system', headerName: 'System', width: 80 },
];

const Languagespage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['languages'], () => fetchLanguages(0))
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})


    useEffect(() => {
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Eine Zeile in der Tabelle ausgewählt. Diesen Datensatz bearbeiten.
    const handleEdit = async ( id ) => {
        const row = await fetchLanguage(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert keine Sprache mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({name: '', leo: '', symbol: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setErrors({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }

    
    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = () => {
        let collect = {}

        if(!values.name){
            collect.name = 'Name eingeben.'
        }

        if(!values.symbol){
            collect.symbol = 'Symbol eingeben.'
        }
        
        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        if(mode === 'create'){
            //Neue Language erstellen.
            const res = await createLanguage(values.name, values.leo, values.symbol, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Sprache erfolgreich angelegt.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Language ändern.
            const res = await editLanguage(values.id, values.name, values.leo, values.symbol, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Sprache erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Eine Language löschen
    const handleDelete = async (id) => {
        if( await isSystem(id) ){
            enqueueSnackbar('Die Systemsprache kann nicht gelöscht werden..', {variant: 'error'})
            return
        }
        const result = await deleteLanguage(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Language erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            if( await isSystem(id) ){
                enqueueSnackbar("Die Systemsprache kann nicht gelöscht werden..", {variant: "error"})
            }else{
                const result = await deleteLanguage(id, apikey)
                if(result.status === 0){
                    enqueueSnackbar(result.message, {variant: "error"})
                }
            }
        }
        refetch()
    }


    
    return (
        <Container>
            <Typography variant="h1">
                Sprachen
            </Typography>
            { isLoggedin() &&
                <div className="data">
                    <div className="data-table-toolbar">
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                            <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                                <AddIcon />
                            </IconButton>
                            <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton color="primary" onClick={refetch}>
                                <RefreshIcon />
                            </IconButton>
                            { isLoading && <HourglassBottomIcon color='warning' />}
                        </Stack>
                        <div className="data-table" style={{ height: 650, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data.results : []}
                                columns={columns}
                                disableSelectionOnClick
                                checkboxSelection
                                pageSize={15}
                                rowsPerPageOptions={[15]}                    
                                disableColumnFilter
                                onRowClick={(e) => handleEdit(e.id)}
                                onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                selectionModel={selectionModel}
                            />                            
                        </div>
                    </div>
                </div>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Sprache ändern...'}
                        { mode === "create" && 'Neue Sprache anlegen...'}
                    </Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2}>
                                { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                <TextField
                                    variant='standard'
                                    label='Name'
                                    id='name'
                                    required
                                    fullWidth
                                    helperText={ errors.name }
                                    error= { errors.name ? true : false  }
                                    value={ values.name }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            name: e.target.value
                                        }))
                                    }}
                                />
                                <TextField
                                    variant='standard'
                                    label='Leo'
                                    id='leo'
                                    fullWidth
                                    value={ values.leo === null ? '' : values.leo }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            leo: e.target.value
                                        }))
                                    }}                                    
                                />
                                <TextField
                                    variant='standard'
                                    label='Symbol'
                                    id='symbol'
                                    required
                                    fullWidth
                                    helperText={ errors.symbol }
                                    error= { errors.symbol ? true : false  }
                                    value={ values.symbol}
                                    inputProps={ {maxLength: 3}}
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            symbol: e.target.value
                                        }))
                                    }}
                                />
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='submit'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        const res = handleCheck()
                                        res && handleSave()
                                    }}
                                >Save</Button>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                { mode==='edit' &&
                                    <Button variant='contained' color="warning"
                                        onClick={() => {
                                            handleDelete( values.id )
                                        }}
                                    >Delete</Button>
                                }
                            </Stack>
                            { mode === 'edit' &&
                                <Alert severity="warning">
                                    <p>Wird die Sprache gelöscht, werden auch alle Übersetzungen (Translations) gelöscht!</p>
                                    <p>Dies kann nicht rückgängig gemacht werden.</p>
                                </Alert>
                            }
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Sprachen wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                            Alle Übersetzungen (Translations) dieser Sprache werden auch gelöscht!<br />
                            Dies kann nicht rückgängig gemacht werden.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}
 
export default Languagespage;