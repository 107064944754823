import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import TranslateIcon from '@mui/icons-material/Translate'
import debounce from "lodash.debounce"
import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { FormGroup, FormControlLabel, Checkbox, InputAdornment, Alert, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchSources, fetchSource, createSource, editSource, deleteSource, exitsSource} from '../includes/dbSourcesFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { fetchLanguages } from '../includes/dbLanguagesFunc'
import { fetchTranslations, createTranslation, deleteTranslation, editTranslation } from '../includes/dbTranslationsFunc'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'source', headerName: 'Source', width: 300 },
    { field: 'key', headerName: 'Key', width: 200 },
    { field: 'languages', headerName: 'Sprachen', width: 300 }
];

const Sourcepage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [mode, setMode] = useState('')
    const [search, setSearch] = useState('')
    const [rowsCount, setRowsCount] = useState(15)
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [translations, setTranslations] = useState({})
    const [languages, setLanguages] = useState(null)
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['sources', apikey, rowsCount, search], () => fetchSources(rowsCount, search))
    const searchInput = useRef()


    useEffect(() => {
        (async () => {
            const answer = await fetchLanguages()
            if(answer && answer.status === 1){ setLanguages(answer.results) }
        })();
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const sourceFetch = await fetchSource(id)
        if(sourceFetch && sourceFetch.status === 1){
            if(sourceFetch.count === 1){
                const source = sourceFetch.results
                const translationFetch = await fetchTranslations(0, source.id)
                const translations = Object.fromEntries(translationFetch.results.map((translation) => [translation.language_id, [translation.translation, translation.id] ]));
                //Also: Translations ist ein Obj. Jede Language hat ein Property. Das Property hat die id der language. Der Wert ist ein Array mit zwei Werten.
                //Erster Wert ist die translation. Zweiter Wert ist die id der translation selber. Diese id gibt es aber nur, wenn eine source bearbeitet wird. Bei einer neuen Source, ist die translation noch nicht gespeichert und kann auch keine id haben.
                //Wenn aber im edit-mode kann es auch sein, dass eine source noch nicht für alle Werte eine translation hat.
                setTranslations(translations)
                setValues(source)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert keine Source mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }

    
    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({source: '', key: '', notranslations: '0'})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setTranslations({})
            setErrors({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async () => {
        let collect = {}
        let keycheck = /[^a-zA-Z0-9_']/

        if(!values.source){
            collect.source = 'Source eingeben.'
        }else if( mode === 'create' && await exitsSource( values.source, null, apikey )){
            collect.source = 'Source wird schon verwendet.'
        }else if( mode === 'edit' && await exitsSource( values.source, values.id, apikey )){
            collect.source = 'Source wird schon verwendet.'
        }

        if( values.key && keycheck.test(values.key)){
            collect.key = "Es sind nur Buchstaben, Zahlen und '_' zulässig!"
        }

        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }


    //Den Drawer (rechte Seite) schliessen
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }
    

    //Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }

    //Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])


    //Eine Source erstellen, oder eine bestehende Source bearbeiten.
    const handleSave = async () => {

        if(mode === 'create'){
            //Neue Source erstellen.
            const res = await createSource(values.source, values.key === '' ? null : values.key, values.notranslations, apikey)
            if(res && res.status === 1){
                const source_id = res.id  //die id der erstellten Source
                enqueueSnackbar("Source erfolgreich angelegt.", {variant: 'success'})
                //jede angegebene translation nun speichern.
                for (const language_id in translations) {
                    const answer = await createTranslation(source_id, language_id, translations[language_id][0], apikey)
                    if( answer.status === 0){
                        enqueueSnackbar("Fehler beim speichern der Übersetzung " + translations[language_id][0] + ". " + answer.message, {variant: 'error'})
                    }
                }
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Source ändern.
            const res = await editSource(values.id, values.source, values.key === '' ? null : values.key, values.notranslations, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Source erfolgreich geändert.", {variant: 'success'})
                //translation erstellen/ändern/löschen.
                //erstellen: im Array ist eine translation als erster Wert, aber keine id als zweiter Wert
                //ändern: im Array ist eine translation als erster Wert, und eine id als zweiter Wert
                //löschen: im Array ist eine translation als erster Wert die ein leerer String ist, und eine id als zweiter Wert
                
                for (const language_id in translations) {
                    const arr = translations[language_id]
                    var answer
                    var operation
                    if(arr.length === 1){
                        //erstellen
                        answer = await createTranslation(values.id, language_id, arr[0], apikey)
                        operation = "erstellen"
                    }else if(arr[0] === ""){
                        //löschen  Der zweite Wert im Array ist die id der translation
                        answer = await deleteTranslation(arr[1], apikey)
                        operation = "löschen"
                    }else{
                        //ändern
                        answer = await editTranslation(arr[1], values.id, language_id, arr[0], apikey)
                        operation = "ändern"
                    }
                    if( answer.status === 0){
                        enqueueSnackbar(`Fehler beim ${operation} der Übersetzung ` + translations[language_id][0] + ". " + answer.message, {variant: 'error'})
                    }
                }

                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }

    //Einen Datensatz löschen
    const handleDelete = async (id) => {
        const result = await deleteSource(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Source erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }
    }

    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteSource(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    return (
        <Container>
            <Typography variant="h1">
                Sources
            </Typography>
            { isLoggedin() &&
                <Stack spacing={2} className="data">
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                        <TextField
                            variant='outlined'
                            label='Suche'
                            fullWidth
                            inputRef={ searchInput }
                            onChange={ (e) => debounceSearch(e.target.value) }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => handleSearch('')}>
                                            <CloseIcon fontSize='small'/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel>Rows</InputLabel>
                            <Select
                                label="Rows"
                                value={rowsCount}
                                onChange={(e) => setRowsCount(e.target.value)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={0}>Alle</MenuItem>
                            </Select>
                        </FormControl>

                    </Stack>
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-toolbar">
                        <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                            <AddIcon />
                        </IconButton>
                        <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={refetch}>
                            <RefreshIcon />
                        </IconButton>
                        { isLoading && <HourglassBottomIcon color='warning' />}
                        <IconButton color="primary" href='/translations'>
                            <TranslateIcon />
                        </IconButton>
                    </Stack>
                    <div className="data-table" style={{ height: 650, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data.results : []}
                            columns={columns}
                            disableSelectionOnClick
                            checkboxSelection
                            pageSize={15}
                            rowsPerPageOptions={[15]}                    
                            disableColumnFilter
                            onRowClick={(e) => handleEdit(e.id)}
                            onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                            selectionModel={selectionModel}
                        />                            
                    </div>
                </Stack>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Source ändern...'}
                        { mode === "create" && 'Neue Source anlegen...'}
                    </Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2}>
                                { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                <TextField
                                    variant='standard'
                                    label='Source'
                                    id='source'
                                    required
                                    helperText={ errors.source }
                                    error= { errors.source ? true : false  }
                                    fullWidth
                                    value={ values.source }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            source: e.target.value
                                        }))
                                    }}
                                />
                                <TextField
                                    variant='standard'
                                    label='Key'
                                    id='key'
                                    helperText={ errors.key ? errors.key : 'Zuweisung zu Programmcode.' }
                                    error= { errors.key ? true : false  }                                    
                                    fullWidth
                                    value={ values.key ? values.key : '' }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            key: e.target.value
                                        }))
                                    }}
                                />
                                <FormGroup>
                                    <FormControlLabel label="Source benötigt keine Übersetzung" control={
                                        <Checkbox
                                            id="notranslations"
                                            checked={ values.notranslations === '1' ? true : false }
                                            onChange={(e) => {
                                                const newValue = e.target.checked === true ? '1' : '0'
                                                setValues(prev =>({
                                                    ...prev,
                                                    notranslations: newValue
                                                }))
                                            }}
                                        />
                                    } />
                                </FormGroup>
                                { (values.notranslations === '0' && languages && languages.length > 1) &&
                                    <Stack spacing={2} className="translations">
                                        <Typography variant='h6' sx={{ marginTop: 5 }}>Übersetzungen</Typography>
                                        {languages.map(language => {
                                            if(language.system==='0'){
                                                return (
                                                    <Stack key={language.id} direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
                                                        {language.leo && 
                                                            <a href={`https://dict.leo.org/${language.leo}/${values.source}`} target="_blank" rel="noopener noreferrer">
                                                                <TranslateIcon color='info'/>
                                                            </a>
                                                        }
                                                        <TextField                                                            
                                                            variant='standard'
                                                            label={language.name}
                                                            fullWidth
                                                            value={ translations[language.id] ? translations[language.id][0] : ""}
                                                            onChange={(e) => {
                                                                //Also: Translations ist ein Obj. Jede Language hat ein Property. Das Property hat die id der language. Der Wert  ist ein Array mit zwei Werten.
                                                                //Erster Wert ist die translation. Zweiter Wert ist die id der tranlation selber. Diese id gibt es aber nur, wenn eine source bearbeitet wird. Bei einer neuen Source, ist die translation noch nicht gespeichert und kann auch keine id haben.
                                                                //Wenn aber im edit-mode kann es auch sein, dass eine source noch nicht für alle Werte eine translation hat.
                                                                const arr = translations[language.id] && translations[language.id].length > 1 ? [e.target.value, translations[language.id][1]] : [e.target.value]
                                                                setTranslations(prev =>({
                                                                    ...prev,
                                                                    [language.id]: arr
                                                                }))
                                                            }}
                                                        />
                                                    </Stack>
                                                )
                                            }else{
                                                return null
                                            }
                                        })}
                                    </Stack>
                                }
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='submit'
                                    onClick={async (e) => {
                                        e.preventDefault()
                                        const res = await handleCheck()
                                        res && handleSave()
                                    }}
                                >Save</Button>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                { mode==='edit' &&
                                    <Button variant='contained' color="warning"
                                        onClick={() => {
                                            handleDelete( values.id )
                                        }}
                                    >Delete</Button>
                                }
                            </Stack>
                            { mode === 'edit' &&
                                <Alert severity="warning">
                                    Wird die Source gelöscht, werden auch alle Übersetzungen gelöscht!<br/>
                                    Dies kann nicht rückgängig gemacht werden.<br/>
                                    {"Wird eine Source in einem Dossier -> Block -> Entry verwendet, kann sie nicht gelöscht werden."}
                                </Alert>
                            }
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Sources wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Wird die Source gelöscht, werden auch alle Übersetzungen gelöscht!<br/>
                        Dies kann nicht rückgängig gemacht werden.<br/>
                        {"Wird eine Source in einem Dossier -> Block -> Entry verwendet, kann sie nicht gelöscht werden."}
                    </Alert>
                    </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}
 
export default Sourcepage;