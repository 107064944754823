import { Button, Container, Skeleton, Stack, Typography, IconButton, Alert, Tooltip, Box } from "@mui/material"
import { useEffect, useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSnackbar } from 'notistack'
import { fetchSourceBySource, createSource, fetchSource } from "../includes/dbSourcesFunc"
import { createBlock, deleteBlock, editBlock, fetchBlock } from "../includes/dbBlocksFunc"
import { createEntry, editEntry, deleteEntry, fetchEntriesFromBlock } from "../includes/dbEntriesFunc"
import { fetchFile } from "../includes/dbFilesFunc"
import { fetchTemplate } from "../includes/dbTemplatesFunc"
import { fetchTemplateentriesFromTemplate } from '../includes/dbTemplateentriesFunc'
import { AuthContext } from '../components/AuthProvider'
import EntryForm from "../components/EntryForm"
import ImageDialog from "../components/ImageDialog"
import SourceTextfield from "../components/SourceTextfield"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add';


const delay = t => new Promise(resolve => setTimeout(resolve, t));


const Blockpage = () => {
    const { id } = useParams()   //ID aus der URL, wenn also der Block 4587 mit blocks/4587 aufgerufen werden soll.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey } = useContext(AuthContext)
    const [ dossierId, setDossierId ] = useState(null)
    const [ templateId, setTemplateId ] = useState(null)
    const [ imageDialogOpen, setimageDialogOpen ] = useState(false)
    const [ image, setImage ] = useState(null)
    const [ title, setTitle ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ update, setUpdate ] = useState(0)
    const [ blockId, setBlockId ] = useState()
    const [ entries, setEntries ] = useState([]);
    const [ errors, setErrors ] = useState([])
    const [ idExists, setIdExists ] = useState('false')
    const navigate = useNavigate()
    

    useEffect(()=>{

        (async () => {
            //Falls eine ID angegeben wurde, wird diese geladen
            setBlockId(id)
            if(id){
                //Bestehender Block laden
                let answer = await fetchBlock( id )
                if(answer.status === 1 && answer.count === 0){
                    setIdExists(false)                
                }else if(answer.status === 1){
                    setIdExists(true)
                    const block = answer.results
                    setDossierId( block.dossier_id )

                    answer = await fetchSource( block.title_id )
                    setTitle( answer.results.source )
                    answer = await fetchSource( block.description_id )
                    setDescription( answer.results.source )
                    const loadImage = await fetchFile( block.image_id )
                    setImage(loadImage.results)

                    answer = await fetchEntriesFromBlock( id )
                    setEntries( answer.results )
                }
            }else{
                //Wenn in der URL eine DossierId übergeben wird, bedeutet dies, dass ein neues Block für dieses Dossier erstellt werden soll.
                const dossierId = new URLSearchParams(window.location.search).get('dossier')
                if(dossierId){
                    setDossierId(dossierId)
                }
                //Wenn in der URL die Id eines Templates übergeben wurde, werden die Werte aus diesem Template eingelesen.
                const templateId = new URLSearchParams(window.location.search).get('template')
                if(templateId){
                    let answer = await fetchTemplate( templateId )

                    if(answer.status === 1){
                        const template = answer.results
                        setTemplateId(template.id)

                        if(template.title_id){
                            answer = await fetchSource( template.title_id )
                            setTitle( answer.results.source )
                        }
                        if(template.description_id){
                            answer = await fetchSource( template.description_id )
                            setDescription( answer.results.source )
                        }
                        if(template.image_id){
                            answer = await fetchFile( template.image_id )
                            setImage(answer.results)
                        }

                        answer = await fetchTemplateentriesFromTemplate( templateId )
                        setEntries( answer.results )
                    }
                }
            }
        })();

    },[ id ])


    //Ein ImageDialog zur Auswahl eines Bildes wurde geschlossen. Als Result wird entweder eine Object eines Bildes (file) oder null zurückgegeben.
    const handleImageDialogClose = ( result ) => {
        if(result){
            setImage(result)
        }
        setimageDialogOpen(false)
    }


    //Ein Entry aus der Auflistung löschen
    const handleRemove = async (index) => {
        const newEntries = [...entries]
        newEntries.splice(index,1)
        setEntries([])   //Wieso auch immer, ohne dies werden die neuen Entries nicht korrekt dargestellt im Browser!
        await delay(1)
        setEntries(newEntries)

        //setEntries(prevState => prevState.filter((item, itemIndex) => itemIndex != index))
    }

    //Diesen Block speichern.
    const handleSave = async () => {
        let aktBlockId = blockId  //müssen wir machen, da wenn ein neuer Block erstellt wird, müssen wir die Id weiter verwenden. Und mit UseState wird blockId async zugewiesen.

        //Als erstes kontrollieren, ob die Daten korrekt sind.
        if(!dossierId){
            enqueueSnackbar('Dieser Block ist keinem Dossier zugeordnet und kann so nicht gespeichert werden.', {variant: 'error'})
            return false
        }
        if(!image){
            enqueueSnackbar('Ein Bild muss ausgewählt werden.', {variant: 'error'})
            setErrors(prev => ({
                ...prev,
                image: 'Bitte ein Bild wählen.'
            }))
            return false
        }else{
            setErrors(prev => ({
                ...prev,
                image: null
            }))
        }
        if(!title){
            enqueueSnackbar('Titel eintragen.', {variant: 'error'})
            return false
        }
        if(!description){
            enqueueSnackbar('Beschreibung darf nicht leer sein.', {variant: 'error'})
            return false
        }
        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            if(!entry || !entry.group_source || !entry.caption_source || !entry.content_source){
                enqueueSnackbar(`Das Entry mit dem Index ${index} ist nicht vollständig definiert.`, {variant: 'error'})
                return false
            }            
        }
        //Nun die Daten speichern, ev. block, sources und entries neu anlegen.
        const block = {dossier_id: dossierId, image_id: image.id}

        //Kontrollieren, ob Source für Title schon existiert, ansonsten anlegen
        let answer = await checkSource(title)
        if(answer){ block.title_id = answer }else{ return false}

        //Kontrollieren, ob Source für Description schon existiert
        answer = await checkSource(description)
        if(answer){ block.description_id = answer }else{ return false}
        
        if(!aktBlockId){
            //Block existiert noch nicht, und muss neu angelegt werden.
            answer = await createBlock( dossierId, image.id, block.title_id, block.description_id, apikey)
            if(answer.status === 1){
                aktBlockId = answer.id
                setBlockId(aktBlockId)
                enqueueSnackbar('Block neu angelegt!' , {variant: 'success'})
            }else{
                enqueueSnackbar('Block konnte nicht neu erstellt werden! ' + answer.message , {variant: 'error'})
                return false
            }
        }else{
            //Block ändern.
            answer = await editBlock( aktBlockId, dossierId, image.id, block.title_id, block.description_id, apikey)
            if(answer.status === 1){
                enqueueSnackbar('Block erfolgreich geändert!' , {variant: 'success'})
            }else{
                enqueueSnackbar('Block konnte nicht geändert werden! ' + answer.message , {variant: 'error'})
                return false
            }
        }

        //Entries
        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            //Sources des Entries neu erstellen wenn noch nicht vorhanden.
            const groupId = await checkSource(entry.group_source)
            const captionId = await checkSource(entry.caption_source)
            const contentId = await checkSource(entry.content_source)
            const linkId = entry.link_source ? await checkSource(entry.link_source) : null
            if( !groupId || !captionId || !contentId || ( entry.link_source && !linkId )){
                enqueueSnackbar(`Probleme beim Erstellen einer Source des Entries mit dem Index ${index}.`, {variant: 'error'})
                return false
            }

            //Entry anlagen oder ändern
            if(entry.id){
                //Entry ändern
                answer = await editEntry(entry.id, aktBlockId, groupId, captionId, contentId, linkId, apikey)
                if(!answer.status === 1){
                    enqueueSnackbar(`Probleme beim Aendern des Entries mit dem Index ${index}.`, {variant: 'error'})
                    return false
                }
            }else{
                //Entry neu anlegen
                answer = await createEntry(aktBlockId, groupId, captionId, contentId, linkId, apikey)
                if(answer.status === 1){
                    entry.id = answer.id
                }else{
                    enqueueSnackbar(`Probleme beim Erstellen des Entries mit dem Index ${index}.`, {variant: 'error'})
                    return false
                }
            }
        }
        enqueueSnackbar('Entries erfolgreich gespeichert.' , {variant: 'success'})

        //Entries löschen. Alle Entries die in der DB vorhanden sind, aber nicht im Array müssen gelöscht werden:
        answer = await fetchEntriesFromBlock( aktBlockId )
        if(answer.status === 1){
            const dbEntries = answer.results

            for (let index = 0; index < dbEntries.length; index++) {
                const dbEntry = dbEntries[index];
                if(!entries.find(entry => entry.id === dbEntry.id)){
                    answer = await deleteEntry( dbEntry.id, apikey )
                    if(answer.status === 1){
                        enqueueSnackbar(`Entry mit der Id ${dbEntry.id} gelöscht!` , {variant: 'success'})
                    }else{
                        enqueueSnackbar(`Probleme beim Löschen des Entries mit der Id ${dbEntry.id}. ${answer.message}`, {variant: 'error'})
                        return false
                    }
                }
            }
        }else{
            enqueueSnackbar('Probleme beim Datenbankzugriff. Abfrage aller Entries nicht möglich.', {variant: 'error'})
            return false            
        }

        setUpdate(prev => prev + 1 )  //Die SourceTextfields updaten, damit sich die Icons aufgrund der neu erstellten Sources anpassen.
        enqueueSnackbar('Erfolgreich gespeichert!' , {variant: 'success'})
    }


    //Den aktuellen Block löschen.
    const handleDelete = async () => {
        if(blockId){
            //Dieser Block wurde in der DB gespeichert und muss nun gelöscht werden:
            const answer = await deleteBlock(blockId, apikey)
            if(answer.status === 1){
                enqueueSnackbar('Block erfolgreich gelöscht!' , {variant: 'success'})
            }else{
                enqueueSnackbar('Probleme beim Datenbankzugriff. Block konnte nicht gelöscht werden.', {variant: 'error'})
                return false
            }
        }
        
        if(dossierId){
            navigate('/dossiers/' + dossierId)
        }else{
            navigate('/dossiers')
        }
    }


    //Falls die Source nicht existiert, diese neu anlegen.
    //Return: Id der schon vorhandenen oder neu angelegten Source oder False bei einem Fehler.
    const checkSource = async ( source ) => {
        let answer = await fetchSourceBySource(source)
        if(answer.status === 1 && answer.count === 0){
            //Source muss neu angelegt werden.
            answer = await createSource(source, null, 0, apikey)
            if(answer.status === 1){ return answer.id }else{
                enqueueSnackbar('Fehler beim Erstellen einer Source! ' + answer.message , {variant: 'error'})
                return false
            }
        }else if(answer.status === 0){
            enqueueSnackbar('Fehler beim Erstellen einer Source! ' + answer.message , {variant: 'error'})
            return false
        }else{
            return answer.id
        }
    }


    return ( 
        <Container>
            <Typography variant='h1'>
                Block
            </Typography>
            <Stack direction='column' spacing={1} sx={{ mb: 2, ml: 1}}>
                { blockId && 
                    <Typography variant="body1">Block-Id: { blockId }</Typography>                
                }
                { !dossierId &&
                    <Alert severity="error">Dieser Block wurde keinem Dossier zugeordnet und kann so nicht gespeichert werden.</Alert>
                }
                { dossierId &&
                    <Typography variant='caption' color="primary">Von Dossier-Nummer {dossierId}</Typography>
                }
                { templateId &&
                    <Typography variant='caption' color="primary">Aus Template-Nummer {templateId}</Typography>
                }
            </Stack>

            { ( id && !idExists ) ? (
                <Typography>Das Template mit der Id {templateId} existiert nicht.</Typography>
            ) : (
                <Box>
                    <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                        <Button
                            onClick={() => setimageDialogOpen(true)}
                        >
                            { image ?
                                <Tooltip
                                    title={
                                        <>
                                        <p>{image.name}</p>
                                        <p>{image.description}</p>
                                        <Button variant='contained' href={'/files/' + image.id}>go to</Button>
                                        </>
                                    }
                                >
                                    <img
                                        onLoad={(e) => {
                                            if(e.target.naturalWidth > 100 || e.target.naturalHeight > 100){
                                                setErrors(prev => ({
                                                    ...prev,
                                                    image: `Bild sollte nicht grösser sein als 100x100px. Aktuell sind es ${e.target.naturalWidth}x${e.target.naturalHeight}`
                                                }))
                                            }else{
                                                setErrors(prev => ({
                                                    ...prev,
                                                    image: null
                                                }))
                                            }
                                        }}
                                        src={process.env.REACT_APP_IMAGEPFAD + image.path}
                                        alt={image.alt}
                                        style={{height: 100, maxWidth: 400}}
                                    />
                                </Tooltip>
                            : 
                                <Skeleton variant="rounded" width={100} height={100} />
                            }
                        </Button>
                        <Stack  direction="column" justifyContent="flex-start" alignItems="stretch" spacing={1}>
                            <SourceTextfield label="Titel" update={ update } required={true} sx={{width: 300}} value={ title } onChange={(n) => setTitle(n)}/>
                            <SourceTextfield label="Beschreibung" update={ update } required={true} sx={{width: 300}} value={ description } onChange={(n) => setDescription(n)}/>
                        </Stack>
                        { errors.image && <Alert severity="warning">{errors.image}</Alert>}

                    </Stack>

                    <Typography variant='h4' sx={{mt: 5}}>Entries</Typography>
                    
                    <Stack direction='column' spacing={2} alignItems="flex-start">
                        {entries &&
                            entries.map((entry, index) => (
                                <Stack key={ index } direction='row' spacing={1} alignItems="flex-end" >
                                    <IconButton onClick={() => handleRemove( index )}>
                                        { entry && entry.id ? <DeleteOutlineIcon color="primary" /> : <ClearIcon color="primary"/> } 
                                    </IconButton>
                                    <Typography variant='body1' color='primary' style={{marginBottom: 4, marginRight: 15}}>{index}</Typography>
                                    <EntryForm entry={ entry } update={ update }/>
                                </Stack>
                            ))
                        }
                        <IconButton
                            onClick={() => {
                                setEntries([...entries, {}])
                            }}
                        >
                            <AddIcon color="primary"/> 
                        </IconButton>
                    </Stack>

                    <Stack direction='row' spacing={1} sx={{mt: 5}}>
                        <Button
                            variant="contained"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if(dossierId){
                                    navigate('/dossiers/' + dossierId)
                                }else{
                                    navigate('/dossiers')
                                }
                            }}
                        >
                            zum Dossier
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={handleDelete}
                        >
                            Löschen
                        </Button>

                    </Stack>
                </Box>
            )}

            <ImageDialog
                keepMounted
                open={ imageDialogOpen }
                onClose={(e) => handleImageDialogClose(e)}
            />
        </Container>
     );
}



export default Blockpage;