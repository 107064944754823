import { Toolbar } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";
import Languagenav from "./Languagenav";
import Usernav from "./Usernav";

const Layout = ( { children }) => {
    const { isLoggedin } = useContext(AuthContext)

    return ( 
        <div className="Layout" >
                { isLoggedin() && (
                    <div>
                        <Usernav />
                        <Toolbar /> 
                    </div>
                )}
                { children }
        </div>
     );
}
 
export default Layout;