import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Skeleton, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert, Tooltip } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchContacts, fetchContact, createContact, editContact, deleteContact, exitsContact} from '../includes/dbContactsFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import ImageDialog from '../components/ImageDialog'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'emailquote', headerName: 'Mail-Angebot', width: 150 },
    { field: 'emailorder', headerName: 'Mail-Bestellung', width: 150 },
    { field: 'advisorname', headerName: 'Betreuer', width: 150 },
    { field: 'companyname', headerName: 'Firma', width: 150 },
    { field: 'companycity', headerName: 'Ort', width: 150 },
];

const Contactspage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['contacts'], () => fetchContacts(0))
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [imageDialogOpen, setimageDialogOpen] = useState(false)
    const [errors, setErrors] = useState({})
    const [imagedialogfor, setImagedialogfor] = useState('')

    useEffect(() => {
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])
    

    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const row = await fetchContact(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein Contact mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setErrors({})
            setValues({name: '', emailquote: '', emailorder: '', advisorimage_id: '', advisorname: '', advisoremail: '', advisorphone: '', companyimage_id: '', companyname: '', companyaddress: '', companyzip: '', companycity: '', companycountry: '', companyphone: '', companyemail: '', companyweb: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }


    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () => {
        setIsDrawerOpen(false)
    }

    //Eingaben kontrollieren und Error-Text erstellen
    const handleCheck = async () => {
        let collect = {}
        let emailcheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!values.name){
            collect.name = 'Name fehlt.'
        }else if( mode === 'create' && await exitsContact( values.name, null, apikey )){
            collect.name = 'Name wird schon verwendet.'
        }else if( mode === 'edit' && await exitsContact( values.name, values.id, apikey )){
            collect.name = 'Name wird schon verwendet.'
        }

        if(!values.emailquote){
            collect.emailquote = 'Bitte E-Mail eintragen.'
        }else if( !emailcheck.test(values.emailquote)){
            collect.emailquote = 'Dies ist keine E-Mail!'
        }

        if(!values.emailorder){
            collect.emailorder = 'Bitte E-Mail eintragen.'
        }else if( !emailcheck.test(values.emailorder)){
            collect.emailorder = 'Dies ist keine E-Mail!'
        }

        if(!values.advisorimage_id){
            collect.advisorimage_id = 'Bitte ein Bild auswählen.'
        }
        
        if(!values.advisorname){
            collect.advisorname = 'Bitte Betreuer-Name eingeben.'
        }

        if(!values.advisoremail){
            collect.advisorname = 'Bitte E-Mail eintragen.'
        }else if( !emailcheck.test(values.advisoremail)){
            collect.advisoremail = 'Dies ist keine E-Mail!'
        }

        if(!values.advisorphone){
            collect.advisorphone = 'Bitte Betreuer-Telefonnummer eingeben.'
        }

        if(!values.companyimage_id){
            collect.companyimage_id = 'Bitte ein Bild auswählen.'
        }
        
        if(!values.companyname){
            collect.companyname = 'Firmenname fehlt.'
        }

        if(!values.companyaddress){
            collect.companyaddress = 'Firmenstrasse eintragen.'
        }

        if(!values.companyzip){
            collect.companyzip = 'Postleitzahl darf nicht leer sein.'
        }

        if(!values.companycity){
            collect.companycity = 'Bitte Ort eingeben.'
        }

        if(!values.companycountry){
            collect.companycountry = 'Das Land fehlt.'
        }

        if(!values.companyphone){
            collect.companyphone = 'Die Eingabe einer Telefonnummer ist notwendig.'
        }

        if(!values.companyemail){
            collect.companyemail = 'Bitte E-Mail eintragen.'
        }else if( !emailcheck.test(values.companyemail)){
            collect.companyemail = 'Dies ist keine E-Mail!'
        }

        if(!values.companyweb){
            collect.companyweb = 'Die Web-Adresse (URL) fehlt.'
        }

        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        if(mode === 'create'){
            //Neuen Contact erstellen.
            const res = await createContact(values.name, values.emailquote, values.emailorder, values.advisorimage_id, values.advisorname, values.advisoremail, values.advisorphone, values.companyimage_id, values.companyname, values.companyaddress, values.companyzip, values.companycity, values.companycountry, values.companyphone, values.companyemail, values.companyweb, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Contact erfolgreich angelegt.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Brand ändern.
            const res = await editContact(values.id, values.name, values.emailquote, values.emailorder, values.advisorimage_id, values.advisorname, values.advisoremail, values.advisorphone, values.companyimage_id, values.companyname, values.companyaddress, values.companyzip, values.companycity, values.companycountry, values.companyphone, values.companyemail, values.companyweb, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Contact erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Ein Contact löschen
    const handleDelete = async (id) => {
        const result = await deleteContact(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Contact erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteContact(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    //Ein ImageDialog zur Auswahl eines Betruer-Bildes wurde geschlossen. Als Result wird entweder eine Object eines Bildes (file) oder null zurückgegeben.
    const handleImageDialogClose = ( result ) => {
        if(result){
            switch (imagedialogfor) {
                case 'advisorimage':
                    setValues(prev =>({
                        ...prev,
                        advisorimage_id: result.id,
                        advisorimage_path: result.path,
                        advisorimage_alt: result.alt
                    }))
                    break;
                case 'companyimage':
                    setValues(prev =>({
                        ...prev,
                        companyimage_id: result.id,
                        companyimage_path: result.path,
                        companyimage_alt: result.alt
                    }))
                    break;
                default:
            }
        }
        setImagedialogfor('')
        setimageDialogOpen(false)
    }


    //Beim Laden eines Betreuer-Bilds! Kontrolle der Dimensionen
    const handleImageLoad = ( { target:img }, imagefor) => {
        let errmsg = null
        if (img.naturalHeight > 200 || img.naturalWidth > 200){
            errmsg = `Dimensionen sollten nicht grösser als 200x200px sein! Aktuell: ${img.naturalWidth}x${img.naturalHeight}`
        }
        switch (imagefor) {
            case 'advisorimage':
                setErrors(prev => ({
                    ...prev,
                    advisorimage_id: errmsg
                }))
                break;
            case 'companyimage':
                setErrors(prev => ({
                    ...prev,
                    companyimageimage_id: errmsg
                }))
                break;
            default:
        }
    }


    

    return (
        <Container>
            <Typography variant="h1">
                Contacts
            </Typography>
            { isLoggedin() &&
                <div className="data">
                    <div className="data-table-toolbar">
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                            <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                                <AddIcon />
                            </IconButton>
                            <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton color="primary" onClick={refetch}>
                                <RefreshIcon />
                            </IconButton>
                            { isLoading && <HourglassBottomIcon color='warning' />}
                        </Stack>
                        <div className="data-table" style={{ height: 650, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data.results : []}
                                columns={columns}
                                disableSelectionOnClick
                                checkboxSelection
                                pageSize={15}
                                rowsPerPageOptions={[15]}                    
                                disableColumnFilter
                                onRowClick={(e) => handleEdit(e.id)}
                                onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                selectionModel={selectionModel}
                            />                            
                        </div>
                    </div>
                </div>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Contact ändern...'}
                        { mode === "create" && 'Neuen Contact anlegen...'}
                    </Typography>
                    <Paper
                        elevation={0}
                        component="form"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2}>
                                { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                <TextField
                                    variant='standard'
                                    label='Name'
                                    id='name'
                                    required
                                    fullWidth
                                    helperText={ errors.name }
                                    error= { errors.name ? true : false  }
                                    value={ values.name }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            name: e.target.value
                                        }))
                                    }}
                                />
                                <TextField
                                    variant='standard'
                                    label='E-Mail Angebot'
                                    id='emailquote'
                                    type="email"
                                    required
                                    fullWidth
                                    helperText={ errors.emailquote }
                                    error= { errors.emailquote ? true : false  }
                                    value={ values.emailquote }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            emailquote: e.target.value
                                        }))
                                    }}
                                />
                                <TextField
                                    variant='standard'
                                    label='E-Mail Bestellung'
                                    id='emailorder'
                                    type="email"
                                    required
                                    fullWidth
                                    helperText={ errors.emailorder }
                                    error= { errors.emailorder ? true : false  }
                                    value={ values.emailorder }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            emailorder: e.target.value
                                        }))
                                    }}
                                />
                                <Stack direction='column' alignItems='flex-start' spacing={1} sx={{ p: 2, border: '1px solid silver', borderRadius: '5px'}}>
                                    <Typography variant='body1' color='primary' mt={'-27px'} p={'5px'} sx={{backgroundColor: 'white'}}>Betreuer</Typography>
                                    <Button
                                        onClick={() => {
                                            setImagedialogfor('advisorimage')
                                            setimageDialogOpen(true)
                                        }}
                                    >
                                        { values.advisorimage_path ?
                                            <Tooltip
                                                title={
                                                    <>
                                                    <p>{values.advisorimage_name}</p>
                                                    <p>{values.advisorimage_description}</p>
                                                    <Button variant='contained' href={'/files/' + values.advisorimage_id}>go to</Button>
                                                    </>
                                                }
                                            >
                                                <img
                                                    onLoad={(e) => handleImageLoad(e, 'advisorimage')}
                                                    src={process.env.REACT_APP_IMAGEPFAD + values.advisorimage_path}
                                                    alt={values.advisorimage_alt}
                                                    style={{maxHeight: 50, maxWidth: 100}}
                                                />
                                            </Tooltip>
                                        : 
                                            <Skeleton variant="rounded" width={50} height={50} />
                                        }
                                    </Button>
                                    { errors.advisorimage_id && 
                                        <Alert severity='error'>{ errors.advisorimage_id }</Alert>
                                    }
                                    <TextField
                                        variant='standard'
                                        label='Betreuer Name'
                                        id='advisorname'
                                        required
                                        fullWidth
                                        helperText={ errors.advisorname }
                                        error= { errors.advisorname ? true : false  }
                                        value={ values.advisorname }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                advisorname: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='Betreuer E-Mail'
                                        id='advisoremail'
                                        type='email'
                                        required
                                        fullWidth
                                        helperText={ errors.advisoremail }
                                        error= { errors.advisoremail ? true : false  }
                                        value={ values.advisoremail }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                advisoremail: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='Betreuer Telefon'
                                        id='advisorphone'
                                        required
                                        fullWidth
                                        helperText={ errors.advisorphone }
                                        error= { errors.advisorphone ? true : false  }
                                        value={ values.advisorphone }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                advisorphone: e.target.value
                                            }))
                                        }}
                                    />
                                </Stack>
                                <Stack direction='column' alignItems='flex-start' spacing={1} sx={{ p: 2, border: '1px solid silver', borderRadius: '5px'}}>
                                    <Typography variant='body1' color='primary' mt={'-27px'} p={'5px'} sx={{backgroundColor: 'white'}}>Firma</Typography>
                                    <Button
                                        onClick={() => {
                                            setImagedialogfor('companyimage')
                                            setimageDialogOpen(true)
                                        }}
                                    >
                                        { values.companyimage_path ?
                                            <Tooltip
                                                title={
                                                    <>
                                                    <p>{values.companyimage_name}</p>
                                                    <p>{values.companyimage_description}</p>
                                                    <Button variant='contained' href={'/files/' + values.companyimage_id}>go to</Button>
                                                    </>
                                                }
                                            >
                                                <img
                                                        onLoad={(e) => handleImageLoad(e, 'companyimage')}
                                                        src={process.env.REACT_APP_IMAGEPFAD + values.companyimage_path}
                                                        alt={values.companyimage_alt}
                                                        style={{maxHeight: 50, maxWidth: 100}}
                                                    />
                                            </Tooltip>
                                        : 
                                            <Skeleton variant="rounded" width={50} height={50} />
                                        }
                                    </Button>
                                    { errors.companyimage_id && 
                                        <Alert severity='error'>{ errors.companyimage_id }</Alert>
                                    }
                                    <TextField
                                        variant='standard'
                                        label='Firma'
                                        id='companyname'
                                        required
                                        fullWidth
                                        helperText={ errors.companyname }
                                        error= { errors.companyname ? true : false  }
                                        value={ values.companyname }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                companyname: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='Strasse'
                                        id='companyaddress'
                                        required
                                        fullWidth
                                        helperText={ errors.companyaddress }
                                        error= { errors.companyaddress ? true : false  }
                                        value={ values.companyaddress }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                companyaddress: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='PLZ'
                                        id='companyzip'
                                        required
                                        fullWidth
                                        helperText={ errors.companyzip }
                                        error= { errors.companyzip ? true : false  }
                                        value={ values.companyzip }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                companyzip: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='Ort'
                                        id='companycity'
                                        required
                                        fullWidth
                                        helperText={ errors.companycity }
                                        error= { errors.companycity ? true : false  }
                                        value={ values.companycity }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                companycity: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='Land'
                                        id='companycountry'
                                        required
                                        fullWidth
                                        helperText={ errors.companycountry }
                                        error= { errors.companycountry ? true : false  }
                                        value={ values.companycountry }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                companycountry: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='Telefon'
                                        id='companyphone'
                                        required
                                        fullWidth
                                        helperText={ errors.companyphone }
                                        error= { errors.companyphone ? true : false  }
                                        value={ values.companyphone }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                companyphone: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='Fimren E-Mail'
                                        id='companyemail'
                                        required
                                        fullWidth
                                        helperText={ errors.companyemail }
                                        error= { errors.companyemail ? true : false  }
                                        value={ values.companyemail }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                companyemail: e.target.value
                                            }))
                                        }}
                                    />
                                    <TextField
                                        variant='standard'
                                        label='Web-Adresse'
                                        id='companyweb'
                                        required
                                        fullWidth
                                        helperText={ errors.companyweb }
                                        error= { errors.companyweb ? true : false  }
                                        value={ values.companyweb }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                companyweb: e.target.value
                                            }))
                                        }}
                                    />
                                </Stack>
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='submit'
                                    onClick={async (e) => {
                                        e.preventDefault()
                                        const res = await handleCheck()
                                        res && handleSave()                                        
                                    }}
                                >Save</Button>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                { mode==='edit' &&
                                    <Button variant='contained' color="warning"
                                        onClick={() => {
                                            handleDelete( values.id )
                                        }}
                                    >Delete</Button>
                                }
                            </Stack>
                            { mode === 'edit' &&
                                <Alert severity="warning">
                                    Wenn dieser Contact in einem Dossier genutzt wird, kann er nicht mehr gelöscht werden.
                                </Alert>
                            }
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie den ausgewählten Contact wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Wenn dieser Contact in einem Dossier genutzt wird, kann er nicht mehr gelöscht werden.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
            <ImageDialog
                keepMounted
                open={ imageDialogOpen }
                onClose={(e) => handleImageDialogClose(e)}
            />
        </Container>
     );
}
 
export default Contactspage;