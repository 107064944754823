import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Chip, OutlinedInput, Button, DialogTitle, DialogContent, DialogActions, Dialog, TextField, Stack, InputLabel, Select, FormControl, MenuItem } from '@mui/material'
import { fetchFiles, getFileTypes } from '../includes/dbFilesFunc'
import debounce from "lodash.debounce"
import { Box } from '@mui/system'

  
function FilesDialog(props) {
    const { onClose, open, ...other } = props;
    const [ options, setOptions ] = useState([])
    const [ fileTypes, setFileTypes ] = useState([])
    const [ filterTypes, setFilterTypes ] = useState([])
    const [ searchText, setSearchText ] = useState([])

    useEffect(() => {
        if (!open) {
            (async () => {
                const types = await getFileTypes()
                if(types){ setFileTypes(types) }
            })();            
        }
    }, [open]);


    const handleEntering = () => {
    };


    const handleCancel = () => {
        onClose();
    };
    

    const handleSelect = ( option ) => {
        onClose(option);
    };


    const handleSearch = async ( search, types ) => {
        if( search === ''){
            setOptions([])
        }else{
            debounceSearch( search , types )
        }
        setSearchText(search)
    }


    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( async ( search, types ) => {
            const answer = await fetchFiles( search, 50, null, types)
            if(answer && answer.status === 1){
                setOptions( answer.results )
            }else{
                setOptions([])
            }
        }, 200)
    ,[])

    
    const handleFilterChange = (event) => {
        const { target: { value } } = event
        const types = typeof value === 'string' ? value.split(',') : value
        setFilterTypes(types)
        handleSearch(searchText, types)
    };

    

    return (
        <Dialog
            className='files-dialog'
            maxWidth='80%'
            sx={{ '& .MuiDialog-paper': { width: '80%', height: '80%' } }}
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
        <DialogTitle>Files</DialogTitle>
        <DialogContent dividers>
            <Stack direction='row' spacing={0.5}>
                <TextField fullWidth autoComplete='off' label='Suche...' value={ searchText } onChange={(e) => handleSearch(e.target.value, filterTypes)}/>
                <FormControl sx={{width: 450}}>
                    <InputLabel id='select-label'>Type</InputLabel>
                    <Select
                        labelId='select-label'
                        input={<OutlinedInput id="select-multiple-chip" label="Type" />}
                        multiple
                        value={ filterTypes }
                        onChange={handleFilterChange}
                        renderValue={(selected) => (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} size='small'/>
                                ))}
                            </Box>
                        )}
                    >
                        {fileTypes.map((fileType) => (
                            <MenuItem
                                key={ fileType }
                                value={ fileType }
                            >
                                { fileType }
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <table>
                <tbody>
                    <tr>
                        <th>id</th>
                        <th>Name</th>
                        <th>Typ</th>
                        <th>Thumb</th>
                    </tr>
                    { options &&
                        options.map(option => (
                            <tr key={option.id} onClick={() => handleSelect(option)} style={{cursor: 'pointer'}}>
                                <td>{ option.id }</td>
                                <td>{ option.name }</td>
                                <td>{ option.filetype }</td>
                                <td>{ option.filetype === 'image' && <img src={process.env.REACT_APP_IMAGEPFAD + option.path} alt={option.alt} style={{maxHeight: 30, maxWidth: 60}}/>}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>



        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleCancel}>
            Cancel
            </Button>
        </DialogActions>
        </Dialog>
    );
    }
    
    FilesDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
    };

    export default FilesDialog;