import { coreFetch } from './dbCoreFunc'


//Alle Brands abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem brands als results
export const fetchBrands = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = 'https://my-drive.id/api/brands/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Brand abfragen mit einer id
//id: id des brands die abgefragt werden soll.
//return: ein Object, mit status, message, und dem brand als results
export const fetchBrand = async ( id ) => {
    var uri = 'https://my-drive.id/api/brands/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Brand erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createBrand(name, usestyle, style, hideadvisor, hidecompany, hidedetails, hideorder, hidequote, logoimage_id, logoimagecontrast_id, apikey){

    const payload = {
        name: name,
        usestyle: usestyle,
        style: style ? style : null,
        hideadvisor: hideadvisor,
        hidecompany: hidecompany,
        hidedetails: hidedetails,
        hideorder: hideorder,
        hidequote: hidequote
    }
    if(logoimage_id !== ''){ payload.logoimage_id = logoimage_id}
    if(logoimagecontrast_id !== ''){ payload.logoimagecontrast_id = logoimagecontrast_id}

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch('https://my-drive.id/api/brands/', 'POST', headers, JSON.stringify(payload))
}


//Ein Brand ändern.
//id:   id des Brands die geändert werden soll
//new{x}: die neuen Werte des Brands
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editBrand(id, name, usestyle, style, hideadvisor, hidecompany, hidedetails, hideorder, hidequote, logoimage_id, logoimagecontrast_id, apikey){
    const payload = {
        name: name,
        usestyle: usestyle,
        style: style ? style : null,
        hideadvisor: hideadvisor,
        hidecompany: hidecompany,
        hidedetails: hidedetails,
        hideorder: hideorder,
        hidequote: hidequote        
    }
    if(logoimage_id && logoimage_id !== ''){payload.logoimage_id = logoimage_id}else{payload.logoimage_id = null}
    if(logoimagecontrast_id && logoimagecontrast_id !== ''){payload.logoimagecontrast_id = logoimagecontrast_id}else{payload.logoimagecontrast_id = null}

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch('https://my-drive.id/api/brands/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Brand löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteBrand(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch('https://my-drive.id/api/brands/' + id, 'DELETE', headers)
}


//Gibt an, ob ein name schon existiert.
//name: name die kontrolliert wird
//exclude: hier kann eine [id] eines Datensatzes angegeben werden, welcher nicht berücksichtigt wird. Wieso? Wenn ein Datensatz geändert wird, darf der name identisch mit dem Wert des zu ändernden Datensatzes sein. Deshalb kann die [id] dieses Datensatzes als exclude angegeben werden. 
//return: true wenn der username schon existiert, ansonsten false
export async function exitsBrand(name, exclude, apikey){
    const params = new URLSearchParams()
    params.append('exists', name)
    if(exclude){ params.append('exclude', exclude) }
    const paramsStr = '?' + params.toString()
    const uri = 'https://my-drive.id/api/brands/' + paramsStr
    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }

    const answer = await coreFetch(uri, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results === 1 ? true : false
    }else{
        return false
    }
}
