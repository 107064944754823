import { coreFetch } from './dbCoreFunc'


//Alle files abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und der files als results
export const fetchFiles = async ( search, limit, offset, filetypes) => {
    if(filetypes && typeof filetypes !== 'string' ){
        //Wenn mehrere Filetypes als Array angegeben wurden.
        if(filetypes.count === 0){
            filetypes = null
        }else{
            filetypes = filetypes.join(';')
        }
    }

    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(offset && offset>0){ params.append('offset', offset) }
    if(search){ params.append('search', "%" + search + "%") }
    if(filetypes){ params.append('filetype', filetypes) }
    const paramsStr = '?' + params.toString()
    const uri = 'https://my-drive.id/api/files/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }
    return await coreFetch(uri, 'GET', headers)
}


//Alle Bilder  abfragen
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//offset: es werden nicht die ersten n Datensätze sondern um den offset versetzte n Datensätze ausgegeben.
//return: ein Object, mit status, message, und der images als results
export const fetchImages = async ( search, limit, offset ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(offset && offset>0){ params.append('offset', offset) }
    if(search){ params.append('search', "%" + search + "%") }
    params.append('filetype', 'image')
    const paramsStr = '?' + params.toString()
    const uri = 'https://my-drive.id/api/files/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }
    return await coreFetch(uri, 'GET', headers)
}




//Ein file abfragen mit einer id
//id: id des files die abgefragt werden soll.
//return: ein Object, mit status, message, und dem file-info als results
export const fetchFile = async ( id ) => {
    var uri = 'https://my-drive.id/api/files/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neues File erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createFile(name, namedark, extension, description, alt, filetype, apikey){
    const payload = {
        name: name,
        namedark: namedark,
        extension: extension,
        description: description,
        alt: alt,
        filetype: filetype
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch('https://my-drive.id/api/files/', 'POST', headers, JSON.stringify(payload))
}


//Ein file auf den Server uploaden
//name:   Dateiname unter dem das File auf dem Server gespeichert wird.
//file:   Das File das hochgeladen werden soll, als FILE.
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Return: Es wird eine Objekt mit status und message zurückgegeben.
export async function uploadFile(name, file, apikey){
    const payload = new FormData()
    payload.append('name', name)
    payload.append('file', file)

    const headers = {
            "Authorization": apikey
    }

    return await coreFetch('https://my-drive.id/api/files/?upload', 'POST', headers, payload)
}


//Ein file ändern.
//id:   id des Files das geändert werden soll
//new{x}: die neuen Werte des files
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editFile(id, description, alt, pathdark, apikey){
    const payload = {
        description: description,
        pathdark: pathdark,
        alt: alt
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch('https://my-drive.id/api/files/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein File löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteFile(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch('https://my-drive.id/api/files/' + id, 'DELETE', headers)
}


//Gibt an, ob ein Dateiname schon existiert.
//name: Dateiname der abgefragt wird, bsp. image.jpg
//return: true wenn der name schon existiert, ansonsten false
export async function fileNameExitst(name){
    const headers = {
        "Content-Type": "application/json",
    }

    const answer = await coreFetch('https://my-drive.id/api/files/?exists=' + name, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results === 1 ? true : false
    }else{
        return false
    }
}


//Gibt eine Array aller bekannten File-Types zurück.
export async function getFileTypes(){
    const headers = {
        "Content-Type": "application/json",
    }

    const answer = await coreFetch('https://my-drive.id/api/files/?getfiletypes', 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results
    }else{
        return false
    }
}