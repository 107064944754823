import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, TextField, Stack } from '@mui/material'
import { mydriveidExitst } from '../includes/dbDossiersFunc'

  
function DuplicateDossierDialog(props) {
  const { onClose, open, id:idProp, name: nameProp, ordernumber: ordernumberProp, quantity: quantityProp, ...other } = props;
  const [ id, setId ] = useState('')
  const [ mydriveid, setMydriveid ] = useState('')
  const [ ordernumber, setOrdernumber ] = useState('')
  const [ name, setName ] = useState('')
  const [ quantity, setQuantity ] = useState('')
  const [ errors, setErrors ] = useState([])


  useEffect(() => {
    if (!open) {
        setId(idProp)
        setName(nameProp)
        setOrdernumber(ordernumberProp)
        setQuantity(quantityProp)
    }
  }, [open, idProp, nameProp, ordernumberProp, quantityProp]);

  const handleEntering = () => {
    setErrors([])
    setMydriveid('')
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = async () => {
    if(await handleCheck()){
      onClose({id: id, mydriveid: mydriveid, name: name, ordernumber: ordernumber, quantity: quantity});
    }
  };

  //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
  const handleCheck = async() => {
    let collect = {}
    if(!mydriveid){
        collect.mydriveid = 'mydrive-id angeben.'
    }else{
        if(await mydriveidExitst(mydriveid)){
            collect.mydriveid = 'mydrive-id ist schon vorhanden. Bitte eine andere wählen.'
        }
    }

    if(!name){
        collect.name = 'Name eingeben.'
    }

    if(!ordernumber){
        collect.ordernumber = 'Bestellnummer eingeben.'
    }

    if(!quantity){
        collect.quantity = 'Menge eintragen.'
    }

    setErrors({
        ...collect
    })
    return Object.keys(collect).length === 0
  }

  
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '70%', height: '80%' } }}
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
        <DialogTitle>Dossier { mydriveid } Duplizieren</DialogTitle>
        <DialogContent dividers>
            <Stack spacing={2}>
                <TextField
                    variant='standard'
                    label='mydrive-id'
                    id='mydriveid'
                    required
                    fullWidth
                    helperText={ errors.mydriveid ? errors.mydriveid : '' }
                    error= { errors.mydriveid ? true : false  }
                    value={ mydriveid ? mydriveid : '' }
                    onChange={ (e) => setMydriveid(e.target.value) }
                />
                <TextField
                    variant='standard'
                    label='Name'
                    id='name'
                    required
                    fullWidth
                    helperText={ errors.name ? errors.name : ''}
                    error= { errors.name ? true : false  }
                    value={ name ? name : '' }
                    onChange={ (e) => setName(e.target.value) }
                />
                <TextField
                    variant='standard'
                    label='Bestellnummer'
                    id='ordernumber'
                    required
                    helperText={ errors.ordernumber ? errors.ordernumber : ''}
                    error= { errors.ordernumber ? true : false  }
                    fullWidth
                    value={ ordernumber ? ordernumber : '' }
                    onChange={ (e) => setOrdernumber(e.target.value) }
                />
                <TextField
                    variant='standard'
                    label='Menge'
                    id='quantity'
                    required
                    helperText={ errors.quantity ? errors.quantity : '' }
                    error= { errors.quantity ? true : false  }
                    fullWidth
                    value={ quantity ? quantity : '' }
                    onChange={ (e) => setQuantity(e.target.value) }
                />                       
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleCancel}>
            Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
    </Dialog>
  );
  }
  
  DuplicateDossierDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    ordernumber: PropTypes.string,
    quantity: PropTypes.string,
  };

  export default DuplicateDossierDialog;