import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, TextField, Stack, Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useQuery } from 'react-query'
import { fetchTemplates } from '../includes/dbTemplatesFunc'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'info', headerName: 'Info', width: 500 }
];



function TemplateDialog(props) {
    const { onClose, open, targetDossierId, ...other } = props;
    const [ searchText, setSearchText ] = useState('')
    const [ selectedId, setSelectedId ] = useState([])
    const { data, refetch } = useQuery(['templates', searchText], () => fetchTemplates(50, searchText))

    const handleEntering = () => {
        refetch()
        setSelectedId([])
    };
    

    const handleCancel = () => {
        onClose();
    };
    

    const handleSelect = ( templateId ) => {
        onClose(templateId);
    };


    const handleSearch = async ( search ) => {
        setSearchText(search)
    }



    return (
        <Dialog
            className='templates-dialog'
            maxWidth='80%'
            sx={{ '& .MuiDialog-paper': { width: '80%', height: '80%' } }}
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
        <DialogTitle>Template auswählen</DialogTitle>
        <DialogContent dividers>
            <Stack direction='row' spacing={0.5} mb={2}>
                <TextField fullWidth autoComplete='off' label='Suche...' value={ searchText } onChange={async(e) => handleSearch(e.target.value)}/>
            </Stack>
            <Box sx={{height: 'calc(100% - 75px)'}}>
                <DataGrid
                    density="compact"
                    rows={data ? data.results : []}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}                    
                    disableColumnFilter
                    onSelectionModelChange={(s) => setSelectedId(s[0])}
                    selectionModel={ selectedId }
                />                            
            </Box>
        </DialogContent>
        <DialogActions>
            <Button
                autoFocus
                disabled={ !(selectedId && selectedId.length > 0) }
                onClick={async () => await handleSelect( selectedId )}
            >
                Select
            </Button>
            <Button autoFocus onClick={handleCancel}>
                Cancel
            </Button>
        </DialogActions>
        </Dialog>
    );
    }
    
    TemplateDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired
    };

    export default TemplateDialog;