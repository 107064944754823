import { AppBar, IconButton, Toolbar, Typography, Box, Button, Divider, List, ListItem, ListItemButton, ListItemText, Drawer } from "@mui/material"
import { useState, useContext, useEffect } from "react"
import MenuIcon from '@mui/icons-material/Menu'
import { NavLink, useNavigate } from "react-router-dom"
import { AuthContext } from "./AuthProvider"

const drawerWidth = 240;

const Usernav = (  ) => {
    const { user } = useContext(AuthContext)
    const [mobileopen, setMobileopen] = useState(false)
    const [navItems, setNavItems] = useState([])
    const navigate = useNavigate()

    useEffect(()=>{
        let items = []
        if(user){
            items = [
                //Menü für eingeloggte user
                {url: '/', name: 'home'},
                {url: '/dossiers', name: 'Dossier'},
                {url: '/sources', name: 'Sources'},
                {url: '/brands', name: "Brands"},
                {url: '/contacts', name: "Contacts"},
                {url: '/files', name: "Files"},
                {url: '/templates', name: "Templates"}
        ]
            if(user.administrator==="1"){
                //Menü für Administratoren.
                items.push(
                    {url: '/users', name: 'user'},
                    {url: '/languages', name: "Sprachen"},
                )
            }
            items.push({url: '/login', name: "logout"})
        }
        setNavItems(items)
    },[user])

    const handleDrawerToggle = () => {
        setMobileopen(!mobileopen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                My-Drive.id
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(item.url)}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return ( 
        <div>
            <AppBar component="nav" className="user-nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block'}}}>
                            My-Drive.id
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <NavLink key={item.name} to={item.url}>
                                <Button sx={{ color: '#fff' }}>
                                    {item.name}
                                </Button>
                            </NavLink>
                        ))}
                    </Box>                    
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileopen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>            
        </div>
     );
}
 
export default Usernav;