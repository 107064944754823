import PropTypes from 'prop-types'
import { Drawer, Container, Stack, Typography, Box, Stepper, Step, StepLabel, TextField, Button, Link } from "@mui/material"
import { useState, useContext, Fragment, useEffect } from "react"
import { LanguageContext } from '../components/LanguageProvider'
import { sendEmail } from "../includes/emailFunc"
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import WarningIcon from '@mui/icons-material/Warning'

  
function OrderDrawer(props) {
    const { onClose, open, mode, orderEmail, quoteEmail, myDriveId, ...other } = props;
    const { language, getLabel } = useContext(LanguageContext)
    const [ values, setValues ] = useState({})
    const [ errors, setErrors ] = useState({})
    const [ activeStep, setActiveStep ] = useState(0)


    useEffect(() => {
        if (open) {
            (async () => {
                setActiveStep(0)
                setErrors({})
                setValues({})
            })();            
        }
    }, [open]);


    const handleBackStep = () => {
        setActiveStep((prev) => prev - 1);
    }


    const handleNextStep = async () => {
        let collect = {}
        let emailcheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        switch (activeStep) {
            case 0:
                if(!values.quantity){ collect.quantity = getLabel('key_inputneeded') }
                break;

            case 1:
                if(!values.company){ collect.company = getLabel('key_inputneeded') }
                if(!values.name){ collect.name = getLabel('key_inputneeded') }
                if(!values.email){
                    collect.email = getLabel('key_inputneeded')
                }else if( !emailcheck.test(values.email) ){
                    collect.email = getLabel('key_incorrectemail')
                }

                if(mode === 'order'){
                    if(!values.street){ collect.street = getLabel('key_inputneeded') }
                    if(!values.zip){ collect.zip = getLabel('key_inputneeded') }
                    if(!values.city){ collect.city = getLabel('key_inputneeded') }
                    if(!values.country){ collect.country = getLabel('key_inputneeded') }
                    if(!values.phone){ collect.phone = getLabel('key_inputneeded') }
                }
                break;
            case 2:
                const actionString = mode === 'order' ? 'Bestellung' : 'Anfrage'
                const subject = `${actionString} von MY-DRIVE.id ${myDriveId}`
                const message = `<h2> ${actionString} von der Plattform my-drive.id</h2>` +
                                '<p>my-drive.id: ' + myDriveId + '</p>' +
                                '<p>Menge: ' + values.quantity + '</p>' +
                                '<p>Anmerkung: ' + values.comment + '</p>' +
                                '<p>Firma: ' + values.company + '</p>' +
                                '<p>Name: ' + values.name + '</p>' +
                                '<p>Strasse: ' + values.street + '</p>' +
                                '<p>PLZ: ' + values.zip + '</p>' +
                                '<p>Ortschaft: ' + values.city + '</p>' +
                                '<p>Land: ' + values.country + '</p>' +
                                '<p>E-Mail: ' + values.email + '</p>' +
                                '<p>Telefon: ' + values.phone + '</p>' +
                                '<p>Action: ' + mode + '</p>' + 
                                '<p>Sprache: ' + language.name + '</p>' 
                const target = mode === 'order' ? orderEmail : quoteEmail
                                
                const answer = await sendEmail(target, values.email, subject, message)
                if(answer.status === 0){
                    collect.send = 'failed'
                }

                break;

            default:
                break;
        }

        setErrors({
            ...collect
        })

        if(Object.keys(collect).length === 0 || activeStep > 1){
            setActiveStep((prev) => prev + 1);
        }
    }



    const handleClose = () => {
        onClose();
    };
    

    return (

        <Drawer
            open={ open }
            onClose={() => handleClose()}
            anchor="bottom"
            PaperProps={{ sx: {height: { xs: 0.8, sm: 0.7 }} }}
            {...other}            
        >
            <Container fixed>
                <Stack flexDirection='column' justifyContent='flex-start' sx={{ margin: '0 10px'  }}>
                    <Typography variant="h5" mt={1}>
                        { mode === 'quote' && getLabel('key_getquote') }
                        { mode === 'order' && getLabel('key_doorder') }
                    </Typography>
                    <Box sx={{marginTop: 2}}>
                        <Stepper orientation="horizontal" activeStep={activeStep}>
                            <Step>
                                <StepLabel>{ getLabel('key_product') }</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>{ getLabel('key_contact') }</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>{ getLabel('key_send') }</StepLabel>
                            </Step>
                        </Stepper>

                        <Fragment>
                            <Box mt={3}>
                                { activeStep === 0 &&
                                    <Stack orientation="row" spacing={2}>
                                        <TextField label={ getLabel('key_quantity') } id='quantity' required value={values.quantity ? values.quantity : ''}
                                            error={ errors.quantity ? true : false } helperText={ errors.quantity }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    quantity: e.target.value
                                                }))
                                            }}/>
                                        <TextField label={ getLabel('key_comment') } id='comment' multiline value={values.comment ? values.comment : ''}
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    comment: e.target.value
                                                }))
                                            }}/>
                                    </Stack>
                                }
                                { activeStep === 1 &&
                                    <Stack orientation="row" spacing={2}>
                                        <TextField label={ getLabel('key_company') } id='company' required value={values.company ? values.company : ''}
                                            error={ errors.company ? true : false } helperText={ errors.company }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    company: e.target.value
                                                }))
                                            }}/>
                                        <TextField label={ getLabel('key_name') } id='name' required value={values.name ? values.name : ''}
                                            error={ errors.name ? true : false } helperText={ errors.name }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    name: e.target.value
                                                }))
                                            }}/>
                                        <TextField label={ getLabel('key_street') } id='street' required={ mode === 'order' } value={values.street ? values.street : ''}
                                            error={ errors.street ? true : false } helperText={ errors.street }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    street: e.target.value
                                                }))
                                            }}/>
                                        <TextField label={ getLabel('key_zip') } id='zip' required={ mode === 'order' } value={values.zip ? values.zip : ''}
                                            error={ errors.zip ? true : false } helperText={ errors.zip }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    zip: e.target.value
                                                }))
                                            }}/>
                                        <TextField label={ getLabel('key_city') } id='city' required={ mode === 'order' } value={values.city ? values.city : ''}
                                            error={ errors.city ? true : false } helperText={ errors.city }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    city: e.target.value
                                                }))
                                            }}/>
                                        <TextField label={ getLabel('key_country') } id='country' required={ mode === 'order' } value={values.country ? values.country : ''}
                                            error={ errors.country ? true : false } helperText={ errors.country }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    country: e.target.value
                                                }))
                                            }}/>
                                        <TextField label={ getLabel('key_email') } id='email' required value={values.email ? values.email : ''}
                                            error={ errors.email ? true : false } helperText={ errors.email }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    email: e.target.value
                                                }))
                                            }}/>
                                        <TextField label={ getLabel('key_phone') } id='phone' required={ mode === 'order' } value={values.phone ? values.phone : ''}
                                            error={ errors.phone ? true : false } helperText={ errors.phone }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    phone: e.target.value
                                                }))
                                            }}/>
                                    </Stack>
                                }
                                { activeStep === 2 &&
                                    <Stack ml={2}>
                                        <Typography color='primary'>{ getLabel('key_product') }</Typography>
                                        <Typography>{ getLabel('key_quantity') + ': ' + values.quantity }</Typography>
                                        { values.comment && <Typography>{ getLabel('key_comment') + ': ' + values.comment }</Typography> }
                                        <Typography color='primary'>{ getLabel('key_contact') }</Typography>
                                        <Typography>{ getLabel('key_company') + ': ' + values.company }</Typography>
                                        <Typography>{ getLabel('key_name') + ': ' + values.name }</Typography>
                                        { values.street && <Typography>{ getLabel('key_street') + ': ' + values.street }</Typography> }
                                        { values.zip && <Typography>{ getLabel('key_zip') + ': ' + values.zip }</Typography> }
                                        { values.country && <Typography>{ getLabel('key_country') + ': ' + values.country }</Typography> }
                                        <Typography>{ getLabel('key_email') + ': ' + values.email }</Typography>
                                        { values.phone && <Typography>{ getLabel('key_phone') + ': ' + values.phone }</Typography> }
                                    </Stack>
                                }
                                { activeStep === 3 &&
                                    <Stack mt={6} justifyContent='flex-start' justifyItems='flex-start' alignItems='center' direction='column'>
                                        { errors.send ? (
                                            <>
                                                <WarningIcon color="warning" fontSize="large" />
                                                { mode === 'order' &&
                                                    <>
                                                        <Typography>{ getLabel('key_ordererror') }</Typography>
                                                        <Link href={'mailto:' + orderEmail}>{ orderEmail }</Link>
                                                    </>
                                                }
                                                { mode === 'quote' && 
                                                    <>
                                                        <Typography>{ getLabel('key_quoteerror') }</Typography>
                                                        <Link href={'mailto:' + quoteEmail}>{ quoteEmail }</Link>
                                                    </>
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <MarkEmailReadIcon color='success' fontSize="large" />
                                                {mode === 'order' && <Typography>{ getLabel('key_ordersuccess') }</Typography>}
                                                {mode === 'quote' && <Typography>{ getLabel('key_quotesuccess') }</Typography>}
                                            </>
                                        ) }
                                        <Button sx={{mt: 2}} onClick={ handleClose }>Close</Button>
                                    </Stack>
                                }
                            </Box>
                            { activeStep < 3 &&
                                <Box sx={{pt:2, pb:1, display: 'flex', flexDirection: 'row'}}>
                                    <Button
                                        disabled={   activeStep === 0}
                                        onClick={handleBackStep}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleNextStep} variant={activeStep < 2 ? 'text' : 'contained' }>
                                        { activeStep < 2 ? 'Next' : 'Send'}
                                    </Button>
                                </Box>
                            }

                        </Fragment>
                    </Box>
                </Stack>
            </Container>
        </Drawer>



    );
    }
    
    OrderDrawer.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        mode: PropTypes.string.isRequired,
        orderEmail: PropTypes.string.isRequired,
        quoteEmail: PropTypes.string.isRequired,
        myDriveId: PropTypes.string.isRequired
    };

    export default OrderDrawer;