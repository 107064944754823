import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import debounce from "lodash.debounce"
import { useContext, useState, useCallback, useRef } from 'react'
import { useQuery } from 'react-query'
import { InputAdornment, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchTemplates, deleteTemplate } from '../includes/dbTemplatesFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'info', headerName: 'Info', width: 500 }
];

const Templatespage = () => {
    const [selectionModel, setSelectionModel] = useState([])
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [rowsCount, setRowsCount] = useState(15)
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['templates', rowsCount, search], () => fetchTemplates(rowsCount, search))
    const searchInput = useRef()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()



    //Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }


    //Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteTemplate(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    return (
        <Container>
            <Typography variant="h1">
                Templates
            </Typography>
            { isLoggedin() &&
                <Stack spacing={2} className="data">
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                        <TextField
                            variant='outlined'
                            label='Suche'
                            fullWidth
                            inputRef={ searchInput }
                            onChange={ (e) => debounceSearch(e.target.value) }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton onClick={() => handleSearch('')}>
                                            <CloseIcon fontSize='small'/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel>Rows</InputLabel>
                            <Select
                                label="Rows"
                                value={rowsCount}
                                onChange={(e) => setRowsCount(e.target.value)}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={0}>Alle</MenuItem>
                            </Select>
                        </FormControl>

                    </Stack>
                    <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-toolbar">
                        <IconButton color="primary" onClick={() => navigate('/template/')}>
                            <AddIcon />
                        </IconButton>
                        <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={refetch}>
                            <RefreshIcon />
                        </IconButton>
                        { isLoading && <HourglassBottomIcon color='warning' />}
                    </Stack>
                    <div className="data-table" style={{ height: 650, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data.results : []}
                            columns={columns}
                            disableSelectionOnClick
                            checkboxSelection
                            pageSize={15}
                            rowsPerPageOptions={[15]}                    
                            disableColumnFilter
                            onRowClick={(e) => navigate('/template/' + e.id)}
                            onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                            selectionModel={selectionModel}
                        />                            
                    </div>
                </Stack>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Templates wirklich löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}
 
export default Templatespage;