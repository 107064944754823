import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, TextField, Stack } from '@mui/material'

  
function CreateTemplateDialog(props) {
    const { onClose, open, name: nameProp, info: infoProp, block: blockProp, ...other } = props;
    const [ name, setName ] = useState('')
    const [ info, setInfo ] = useState('')
    const [ errors, setErrors ] = useState([])


    useEffect(() => {
        if (!open) {
            setName(nameProp)
            setInfo(infoProp)
        }
    }, [open, nameProp, infoProp]);


    const handleEntering = () => {
        setErrors([])
        setName('')
        setInfo('')   
    };


    const handleCancel = () => {
        onClose();
    };


    const handleOk = async () => {
        if(await handleCheck()){
            onClose({name: name, info: info, block: blockProp});
        }
    };


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async() => {
        let collect = {}

        setErrors({
            ...collect
        })
        return Object.keys(collect).length === 0
    }

  
    return (
        <Dialog
        sx={{ '& .MuiDialog-paper': { width: '70%', height: '80%' } }}
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...other}
        >
            <DialogTitle>Template erstellen.</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <TextField
                        variant='standard'
                        label='Name'
                        id='name'
                        required
                        fullWidth
                        helperText={ errors.name ? errors.name : ''}
                        error= { errors.name ? true : false  }
                        value={ name ? name : '' }
                        onChange={ (e) => setName(e.target.value) }
                    />
                    <TextField
                        variant='standard'
                        label='Info'
                        id='info'
                        required
                        multiline
                        helperText={ errors.info ? errors.info : '' }
                        error= { errors.info ? true : false  }
                        fullWidth
                        value={ info ? info : '' }
                        onChange={ (e) => setInfo(e.target.value) }
                    />                       
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                Cancel
                </Button>
                <Button onClick={handleOk}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}
  
CreateTemplateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    name: PropTypes.string,
    info: PropTypes.string,
    block: PropTypes.object
};

export default CreateTemplateDialog;