import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

const ProtectedRoute = ({ children, redirect, onlyadmin }) => {
    const { isLoggedin, isAdmin } = useContext(AuthContext)

    if( isLoggedin() ){
        if(onlyadmin && !isAdmin()){
            return <Navigate to={onlyadmin} replace />
        }else{
            return children
        }
    }else{
        return <Navigate to={redirect} replace />
    }
}
 
export default ProtectedRoute;