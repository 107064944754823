import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Stack } from '@mui/material'
import SourceTextfield from './SourceTextfield'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FilesDialog from './FilesDialog';

  
function EntryForm(props) {
    const { onChange, contentRequired, update, entry: valuesProp, ...other } = props;
    const [ values, setValues ] = useState(valuesProp)
    const [ groupValue, setGroupValue ] = useState('')
    const [ captionValue, setCaptionValue ] = useState('')
    const [ contentValue, setContentValue ] = useState('')
    const [ linkValue, setLinkValue ] = useState('')
    const [ fileDialogOpen, setFileDialogOpen ] = useState(false)


    useEffect(() => {
        if(valuesProp){
            setValues(valuesProp)
            typeof valuesProp.group_source !== 'undefined' ? setGroupValue(values.group_source) : setGroupValue('')
            typeof valuesProp.caption_source !== 'undefined' ? setCaptionValue(values.caption_source) : setCaptionValue('')
            typeof valuesProp.content_source !== 'undefined' ? setContentValue(values.content_source) : setContentValue('')
            typeof valuesProp.link_source !== 'undefined' ? setLinkValue(values.link_source) : setLinkValue('')
        }else{
            setValues({})
            setGroupValue('')
            setCaptionValue('')
            setContentValue('')
            setLinkValue('')
        }
    // eslint-disable-next-line
    }, [valuesProp]);


    useEffect(() => {
        if(values){
            groupValue ? values.group_source = groupValue : delete values.group_source
            captionValue ? values.caption_source = captionValue : delete values.caption_source
            contentValue ? values.content_source = contentValue : delete values.content_source
            linkValue ? values.link_source = linkValue : delete values.link_source
        }
    }, [groupValue, captionValue, contentValue, linkValue, values])


    //Ein ImageDialog zur Auswahl eines Bildes wurde geschlossen. Als Result wird entweder eine Object eines Bildes (file) oder null zurückgegeben.
    const handleFileDialogClose = ( result ) => {
        if(result){
            setLinkValue(result.path)
        }
        setFileDialogOpen(false)
    }


    return (
        <>
            <Stack direction="row" spacing={2} alignItems="flex-end" {...other}>
                <SourceTextfield sx={{width: 150}} label='Gruppe' update={ update } required value={ groupValue } onChange={(v) => setGroupValue(v)} />
                <SourceTextfield sx={{width: 150}} label='Bezeichnung' update={ update } required value={ captionValue } onChange={(v) => setCaptionValue(v)} />
                <SourceTextfield sx={{width: 150}} label='Inhalt' update={ update } required={contentRequired} value={ contentValue } onChange={(v) => setContentValue(v)} />
                <Stack direction='row' spacing={0} alignItems="flex-end">
                    <IconButton
                        onClick={() => {
                            setFileDialogOpen(true)
                        }}
                    ><FindInPageIcon/></IconButton>
                    <SourceTextfield sx={{width: 350}} label='Link' update={ update } value={ linkValue } onChange={(v) => setLinkValue(v)} />
                </Stack>
            </Stack>
            <FilesDialog
                keepMounted
                open={ fileDialogOpen }
                onClose={(e) => handleFileDialogClose(e)}
            />

        </>
    );
}


EntryForm.propTypes = {
    onChange: PropTypes.func,
    entry: PropTypes.object,
    update: PropTypes.number,
    contentRequired: PropTypes.bool
};


EntryForm.defaultProps = {
    contentRequired: true
}


export default EntryForm;