import { coreFetch } from './dbCoreFunc'


export const getTheme = async (name, dark) => {
    const params = new URLSearchParams()
    params.append('name', name)
    if(dark){ params.append('dark', "true") }
    const paramsStr = '?' + params.toString()
    const uri = 'https://my-drive.id/api/themes/' + paramsStr
    const headers = {
        "Content-Type": "application/json"
    }
    return await coreFetch(uri, 'GET', headers)
}