import { Typography, Container, TextField, Paper, Alert, Button} from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthProvider";


const Loginpage = () => {
    const { onLogin, onLogout, isLoggedin } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    //Login-Button-Click
    const handleClick = async (e) => {
        e.preventDefault();
        setLoading(true);
        const username = document.getElementById("username").value
        const password = document.getElementById("password").value
        const {status, message} = await onLogin(username, password)
        if(status){
            setError(null)
            navigate('/');
        }else{
            setError(message)
        }
        setLoading(false);
    }

    return ( 
        <Container>
            <div className="login">
                <Typography variant="h1">
                    { isLoggedin() ? 'Logout' : 'Login'}
                </Typography>

                { isLoggedin() ? (
                    <Button variant="contained" onClick={onLogout}>Logout</Button>
                ) : (
                    <Paper
                        elevation={3}
                        className="login-form"
                        component="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <TextField className="login-input" variant="standard" label="Name" id="username" required fullWidth/>
                        <TextField className="login-input" variant="standard" label="Passwort" id="password" type="password" required fullWidth/>

                        <div>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={ loading }
                                onClick={(e) => handleClick(e)}
                            >
                                Login
                            </LoadingButton>
                            {error && 
                                <div className="alert">
                                    <Alert severity="error" onClose={() => {setError(null)}}>{ error }</Alert>
                                </div>
                            }
                        </div>
                    </Paper>)
            }
            </div>
        </Container>            
     );
}
 
export default Loginpage;