import { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, TextField, Stack, Box } from '@mui/material'
import { fetchBlocks, copyBlock } from '../includes/dbBlocksFunc'
import { DataGrid } from '@mui/x-data-grid'
import { useQuery } from 'react-query'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'



const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'dossier_id', headerName: 'Dossier', width: 80 },
    { field: 'title_source', headerName: 'Name', width: 300 },
    { field: 'description_source', headerName: 'Beschreibung', width: 400 }
];



function CopyBlockDialog(props) {
    const { onClose, open, targetDossierId, ...other } = props;
    const [ searchText, setSearchText ] = useState('')
    const [ selectedIds, setSelectedIds ] = useState([])
    const { data, refetch } = useQuery(['blocks', searchText], () => fetchBlocks(50, searchText))
    const { apikey } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()


    const handleCancel = () => {
        onClose(false);
    };


    const handleEntering = () => {
        refetch()
        setSelectedIds([])
    };
    

    const handleCopy = async () => {
        if(selectedIds.length > 0){

            for (let index = 0; index < selectedIds.length; index++) {
                const id = selectedIds[index];
                //const block = data.results.find((v) => v.id === id)
                //const answer = await createBlock(targetDossierId, block.image_id, block.title_id, block.description_id, apikey)
                const answer = await copyBlock(id, targetDossierId, apikey)

                if(answer.status !== 1){
                    enqueueSnackbar(`Fehler beim Kopieren des Blocks mit der ${id} für das Dossier mit der ${targetDossierId}.`, {variant: 'error'})
                }
            }
            enqueueSnackbar(`Es wurden ${selectedIds.length} Blocks erfolgreich kopiert`, {variant: 'success'})
            onClose(true);
        }
       
    };


    const handleSearch = async ( search ) => {
        setSearchText(search)
        //debounceSearch( search )
    }



    return (
        <Dialog
            className='files-dialog'
            maxWidth='80%'
            sx={{ '& .MuiDialog-paper': { width: '80%', height: '80%' } }}
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
        <DialogTitle>Block kopieren</DialogTitle>
        <DialogContent dividers>
            <Stack direction='row' spacing={0.5} mb={2}>
                <TextField fullWidth autoComplete='off' label='Suche...' value={ searchText } onChange={async(e) => handleSearch(e.target.value)}/>
            </Stack>
            <Box sx={{height: 'calc(100% - 75px)'}}>
                <DataGrid
                    density="compact"
                    rows={data ? data.results : []}
                    columns={columns}
                    checkboxSelection
                    pageSize={15}
                    rowsPerPageOptions={[15]}                    
                    disableColumnFilter
                    onSelectionModelChange={(s) => setSelectedIds(s)}                    
                    selectionModel={ selectedIds }
                />                            
            </Box>
        </DialogContent>
        <DialogActions>
            <Button
                autoFocus
                disabled={ !(selectedIds && selectedIds.length > 0) }
                onClick={async () => await handleCopy()}
            >
                Copy
            </Button>
            <Button autoFocus onClick={handleCancel}>
                Cancel
            </Button>
        </DialogActions>
        </Dialog>
    );
    }
    
    CopyBlockDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        targetDossierId : PropTypes.number.isRequired
    };

    export default CopyBlockDialog;