import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { useContext, useState, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { FormGroup, FormControlLabel, Checkbox, Skeleton, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert, Link, Box, Tooltip } from '@mui/material'
import { DataGrid } from "@mui/x-data-grid"
import { fetchBrands, fetchBrand, createBrand, editBrand, deleteBrand, exitsBrand} from '../includes/dbBrandsFunc'
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import ImageDialog from '../components/ImageDialog'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'usestyle', headerName: 'Style verwenden', width: 200 },
    { field: 'style', headerName: 'Style', width: 200 }
];

const Brandspage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['brands'], () => fetchBrands(0))
    const [selectionModel, setSelectionModel] = useState([])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [imageDialogOpen, setimageDialogOpen] = useState(false)
    const [errors, setErrors] = useState({})
    const [warnings, setWarnings] = useState({})
    const [imagedialogfor, setImagedialogfor] = useState('')
    const imgRefLogo = useRef(null)
    const imgRefLogoContrast = useRef(null)
    



    useEffect(() => {
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])
    

    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const row = await fetchBrand(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein Brand mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({name: '', usestyle: '0', hidequote: '0', hideorder: '0', hideadvisor: '0', hidecompany: '0', style: '', logoimage_id: '', logoimagecontrast_id: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setErrors({})
            setWarnings({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
            setWarnings({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async () => {
        let collectErrors = {}
        let collectWarning = {}
        let namecheck = /[^a-zA-Z0-9_']/

        if(!values.name){
            collectErrors.name = 'Name eingeben.'
        }else if(namecheck.test(values.name)){
            collectErrors.name = "Es sind nur Buchstaben, Zahlen und '_' zulässig!"
        }else if( mode === 'create' && await exitsBrand( values.name, null, apikey )){
            collectErrors.name = 'Name wird schon verwendet.'
        }else if( mode === 'edit' && await exitsBrand( values.name, values.id, apikey )){
            collectErrors.name = 'Name wird schon verwendet.'
        }

        if(imgRefLogo.current){
            if(imgRefLogo.current.naturalHeight > 100 || imgRefLogo.current.naturalWidth > 100){
                collectWarning.logoimage_id = `Dimensionen sollten nicht grösser als 100x100px sein! Aktuell: ${imgRefLogo.current.naturalWidth}x${imgRefLogo.current.naturalHeight}`
            }
        }

        if(imgRefLogoContrast.current){
            if(imgRefLogoContrast.current.naturalHeight > 100 || imgRefLogoContrast.current.naturalWidth > 100){
                collectWarning.logoimagecontrast_id = `Dimensionen sollten nicht grösser als 100x100px sein! Aktuell: ${imgRefLogoContrast.current.naturalWidth}x${imgRefLogoContrast.current.naturalHeight}`
            }
        }
        
        setErrors({
            ...collectErrors
        })
        setWarnings({
            ...collectWarning
        })
        return Object.keys(collectErrors).length === 0
    }
    

    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        if(mode === 'create'){
            //Neuen Brand erstellen.
            const res = await createBrand(values.name, values.usestyle, values.style, values.hideadvisor, values.hidecompany, values.hidedetails, values.hideorder, values.hidequote, values.logoimage_id, values.logoimagecontrast_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Brand erfolgreich angelegt.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Brand ändern.
            const res = await editBrand(values.id, values.name, values.usestyle, values.style, values.hideadvisor, values.hidecompany, values.hidedetails, values.hideorder, values.hidequote, values.logoimage_id, values.logoimagecontrast_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Brand erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Ein Brand löschen
    const handleDelete = async (id) => {
        const result = await deleteBrand(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Brand erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteBrand(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    //Ein ImageDialog zur Auswahl eines Logos wurde geschlossen. Als Result wird entweder eine Object eines Bildes (file) oder null zurückgegeben.
    const handleImageDialogClose = ( result ) => {
        if(result){
            switch (imagedialogfor) {
                case 'logo':
                    setValues(prev =>({
                        ...prev,
                        logoimage_id: result.id,
                        logoimage_path: result.path,
                        logoimage_alt: result.alt
                    }))
                    break;
                case 'logocontrast':
                    setValues(prev =>({
                        ...prev,
                        logoimagecontrast_id: result.id,
                        logoimagecontrast_path: result.path,
                        logoimagecontrast_alt: result.alt
                    }))
                    break;
                default:
            }
        }
        setImagedialogfor('')
        setimageDialogOpen(false)
    }




    return (
        <Container>
            <Typography variant="h1">
                Brands
            </Typography>
            { isLoggedin() &&
                <div>
                    <div className="data">
                        <div className="data-table-toolbar">
                            <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                                <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton color="primary" onClick={refetch}>
                                    <RefreshIcon />
                                </IconButton>
                                { isLoading && <HourglassBottomIcon color='warning' />}
                            </Stack>
                            <div className="data-table" style={{ height: 650, width: '100%' }}>
                                <DataGrid
                                    density="compact"
                                    rows={data ? data.results : []}
                                    columns={columns}
                                    disableSelectionOnClick
                                    checkboxSelection
                                    pageSize={15}
                                    rowsPerPageOptions={[15]}                    
                                    disableColumnFilter
                                    onRowClick={(e) => handleEdit(e.id)}
                                    onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                    selectionModel={selectionModel}
                                />                            
                            </div>
                        </div>
                    </div>
                    <Paper sx={{p: 3}}>
                        <Typography variant='h4'>Infos</Typography>
                        <Typography>Jedem Dossier wird ein Brand zugeordnet. Dadurch kann die Darstellung gesteuert werden.</Typography>
                        <Typography>Primär wird dazu die Theming von Material UI verwendet. Zusätzlich kann auch eine CSS Datei angegeben werden, falls notwenidg.</Typography>

                        <Typography variant='h6'>Material UI Theming</Typography>
                        
                        <Typography>Auf dem Server im Ordner ../themes müssen folgende Dateien erstellt werden:</Typography>
                        <ul>
                            <li><Typography>[brand_name].json: Enthällt das Material UI Theme für den Light-Mode.</Typography></li>
                            <li><Typography>[brand_name]_dark.json: Enthällt das Material UI Theme für den Dark-Mode.</Typography></li>
                        </ul>
                        <Typography fontWeight='bolder'>Wichtig! Die Dateinamen müssen alles Kleinbuchstabe sein, egal ob beim Brand-Name Grossbuchstaben verwendet wurden.</Typography>
                        <Typography>Bsp:</Typography>
                        <Typography>Brand-Name:  Hilba_Brand1</Typography>
                        <Typography>File für Light-Theme:  hilba_brand1.json</Typography>
                        <Typography mb={3}>File für Dark-Theme:  hilba_brand1_dark.json</Typography>
                        <Typography mb={1}>Die Formatierung dieser Dateien sind den unten aufgeführten Links zu entnehmen. Folgend ein Beispiel einer minimalen Konfiguration:</Typography>

                        <Typography variant='body2'>{'{'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 20}}>{'"name": "<<hier der Brand Name. Diese Zeile ist optional.>>",'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 20}}>{'"palette": {'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 40}}>{'"primary": {'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 60}}>{'"main": "#ff9800",'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 60}}>{'"contrastText": "#fff"'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 40}}>{'}'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 20}}>{'},'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 20}}>{'"typography": {'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 40}}>{'"fontFamily": "SansSerif"'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 20}}>{'},'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 20}}>{'"components": {'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 40}}>{'"MuiLink": {'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 60}}>{'"styleOverrides": {'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 80}}>{'"root": {'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 100}}>{'"textDecoration": "none",'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 100}}>{'"borderBottom": "1px dashed" '}</Typography>
                        <Typography variant='body2' sx={{textIndent: 80}}>{'}'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 60}}>{'}'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 40}}>{'}'}</Typography>
                        <Typography variant='body2' sx={{textIndent: 20}}>{'}'}</Typography>
                        <Typography variant='body2'>{'}'}</Typography>
                        <ul>
                            <li><Link target='blank' href='https://mui.com/material-ui/customization/theming/'><Typography>Material UI Theming</Typography></Link></li>
                            <li><Link target='blank' href='https://mui.com/material-ui/customization/default-theme/'><Typography>Material UI Default Theme</Typography></Link></li>
                            <li><Link target='blank' href='https://bareynol.github.io/mui-theme-creator'><Typography>Theme Creator</Typography></Link></li>
                        </ul>

                        <Typography variant='h6'>CSS Datei</Typography>
                        <Typography>Wird im Brand eine CSS-Datei angegeben unter Style (Bsp: /css/brand1.css) muss exakt an diese Path auf dem Server eine Datei mit den benötigten Formatierungen angelegt werden.</Typography>
                        <Typography>Ausserdem muss die Option 'CSS verwenden' aktiv sein. Sollte diese Option aktiv sein, und keine CSS-Datei angegeben oder die angegebene Datei exisitiert nicht, wird die Website trotzdem angezeigt. In der Browser-Inspector-Console wird jedoch eine Warnung ausgegeben.</Typography>
                    </Paper>
                </div>
                


            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Brand ändern...'}
                        { mode === "create" && 'Neuen Brand anlegen...'}
                    </Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2} alignItems='flex-start'>
                                { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                <TextField
                                    variant='standard'
                                    label='Name'
                                    id='name'
                                    required
                                    fullWidth
                                    helperText={ errors.name }
                                    error= { errors.name ? true : false  }
                                    value={ values.name }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            name: e.target.value
                                        }))
                                    }}
                                />


                                <FormGroup>
                                    <FormControlLabel label="CSS verwenden" control={
                                        <Checkbox
                                            id="usestyle"
                                            checked={ values.usestyle === '1' ? true : false }
                                            onChange={(e) => {
                                                const newValue = e.target.checked === true ? '1' : '0'
                                                setValues(prev =>({
                                                    ...prev,
                                                    usestyle: newValue
                                                }))
                                            }}
                                        />
                                    } />
                                </FormGroup>

                                { values.usestyle === '1' && 
                                    <TextField
                                        variant='standard'
                                        label='Style'
                                        id='style'
                                        fullWidth
                                        value={ values.style === null ? '' : values.style }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                style: e.target.value
                                            }))
                                        }}                                    
                                    />
                                }
                                <Stack spacing={0} sx={{border: '1px solid silver', padding: '10px', borderRadius: 2}}>
                                    <Typography>Voreinstellungen beim Dossier</Typography>
                                    <FormGroup>
                                        <FormControlLabel label="Dossier-Details ausblenden" control={
                                            <Checkbox
                                                id="hidedetails"
                                                checked={ values.hidedetails === '1' ? true : false }
                                                onChange={(e) => {
                                                    const newValue = e.target.checked === true ? '1' : '0'
                                                    setValues(prev =>({
                                                        ...prev,
                                                        hidedetails: newValue
                                                    }))
                                                }}
                                            />
                                        } />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel label="Angebot-Bereich ausblenden" control={
                                            <Checkbox
                                                id="hidequote"
                                                checked={ values.hidequote === '1' ? true : false }
                                                onChange={(e) => {
                                                    const newValue = e.target.checked === true ? '1' : '0'
                                                    setValues(prev =>({
                                                        ...prev,
                                                        hidequote: newValue
                                                    }))
                                                }}
                                            />
                                        } />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel label="Bestell-Bereich ausblenden" control={
                                            <Checkbox
                                                id="hideorder"
                                                checked={ values.hideorder === '1' ? true : false }
                                                onChange={(e) => {
                                                    const newValue = e.target.checked === true ? '1' : '0'
                                                    setValues(prev =>({
                                                        ...prev,
                                                        hideorder: newValue
                                                    }))
                                                }}
                                            />
                                        } />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel label="Firmen-Feld ausblenden" control={
                                            <Checkbox
                                                id="hidecompany"
                                                checked={ values.hidecompany === '1' ? true : false }
                                                onChange={(e) => {
                                                    const newValue = e.target.checked === true ? '1' : '0'
                                                    setValues(prev =>({
                                                        ...prev,
                                                        hidecompany: newValue
                                                    }))
                                                }}
                                            />
                                        } />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel label="Betreuer-Bereich ausblenden" control={
                                            <Checkbox
                                                id="hideadvisor"
                                                checked={ values.hideadvisor === '1' ? true : false }
                                                onChange={(e) => {
                                                    const newValue = e.target.checked === true ? '1' : '0'
                                                    setValues(prev =>({
                                                        ...prev,
                                                        hideadvisor: newValue
                                                    }))
                                                }}
                                            />
                                        } />
                                    </FormGroup>
                                </Stack>

                                <Stack direction='row' spacing={4}>
                                    <Box display='flex' alignItems='self-start' flexDirection='column' sx={{p: 2, borderRadius: 2, border: '1px solid silver'}}>
                                        <Typography variant='body1' mb={1}>Logo</Typography>
                                        <Button
                                            onClick={() => {
                                                setImagedialogfor('logo')
                                                setimageDialogOpen(true)
                                            }}
                                        >
                                            { values.logoimage_path ?
                                                <Tooltip
                                                    title={
                                                        <>
                                                        <p>{values.logoimage_name}</p>
                                                        <p>{values.logoimage_description}</p>
                                                        <Button variant='contained' href={'/files/' + values.logoimage_id}>go to</Button>
                                                        </>
                                                    }
                                                >
                                                    <img
                                                        onLoad={handleCheck}
                                                        ref={imgRefLogo}
                                                        src={process.env.REACT_APP_IMAGEPFAD + values.logoimage_path}
                                                        alt={values.logoimage_alt}
                                                        style={{maxHeight: 100, maxWidth: 200}}
                                                    />
                                                </Tooltip>
                                            : 
                                                <Skeleton variant="rounded" width={100} height={100} />
                                            }
                                        </Button>
                                        { values.logoimage_path &&
                                            <IconButton
                                                onClick={() => {
                                                    let v = {...values}
                                                    delete v.logoimage_id
                                                    delete v.logoimage_path
                                                    delete v.logoimage_alt
                                                    setValues(v)
                                                    setWarnings(prev => ({
                                                        ...prev,
                                                        logoimage_id: null
                                                    }))

                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>                                
                                        }
                                        { warnings.logoimage_id && 
                                            <Alert severity='warning'>{ warnings.logoimage_id }</Alert>
                                        }
                                </Box>

                                <Box display='flex' alignItems='self-start' flexDirection='column' sx={{backgroundColor: 'primary.main', p: 2, borderRadius: 2, border: '1px solid silver'}}>
                                        <Typography variant='body1' mb={1} color='primary.contrastText'>Kontrast-Logo</Typography>
                                        <Button
                                            onClick={() => {
                                                setImagedialogfor('logocontrast')
                                                setimageDialogOpen(true)
                                            }}
                                        >
                                            { values.logoimagecontrast_path ?
                                                <Tooltip
                                                    title={
                                                        <>
                                                        <p>{values.logoimagecontrast_name}</p>
                                                        <p>{values.logoimagecontrast_description}</p>
                                                        <Button variant='contained' href={'/files/' + values.logoimagecontrast_id}>go to</Button>
                                                        </>
                                                    }
                                                >
                                                    <img
                                                        onLoad={handleCheck}
                                                        ref={imgRefLogoContrast}
                                                        src={process.env.REACT_APP_IMAGEPFAD + values.logoimagecontrast_path}
                                                        alt={values.logoimagecontrast_alt}
                                                        style={{maxHeight: 100, maxWidth: 200}}
                                                    />
                                                </Tooltip>
                                            : 
                                                <Skeleton variant="rounded" width={100} height={100} sx={{bgcolor: 'grey.300'}}/>
                                            }
                                        </Button>
                                        { values.logoimagecontrast_path &&
                                            <IconButton sx={{color: 'primary.contrastText'}}
                                                onClick={() => {
                                                    let v = {...values}
                                                    delete v.logoimagecontrast_id
                                                    delete v.logoimagecontrast_path
                                                    delete v.logoimagecontrast_alt
                                                    setValues(v)
                                                    setWarnings(prev => ({
                                                        ...prev,
                                                        logoimagecontrast_id: null
                                                    }))
                                                }}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        }
                                        { warnings.logoimagecontrast_id && 
                                            <Alert severity='warning'>{ warnings.logoimagecontrast_id }</Alert>
                                        }
                                </Box>
                            </Stack>
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='submit'
                                    onClick={async (e) => {
                                        e.preventDefault()
                                        const res = await handleCheck()
                                        res && handleSave()
                                    }}
                                >Save</Button>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                { mode==='edit' &&
                                    <Button variant='contained' color="warning"
                                        onClick={() => {
                                            handleDelete( values.id )
                                        }}
                                    >Delete</Button>
                                }
                            </Stack>
                            { mode === 'edit' &&
                                <Alert severity="warning">
                                    Wenn dieser Brand in einem Dossier genutzt wird, kann er nicht mehr gelöscht werden.
                                </Alert>
                            }
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie den ausgewählten Brand wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Wenn dieser Brand in einem Dossier genutzt wird, kann er nicht mehr gelöscht werden.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
            <ImageDialog
                keepMounted
                open={ imageDialogOpen }
                onClose={(e) => handleImageDialogClose(e)}
            />
        </Container>
     );
}
 
export default Brandspage;